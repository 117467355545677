import { Button, Form, Input, Select, Spin } from 'antd'
import React, { useState } from 'react'
import { ShopFormModel } from '../../../interfaces/shop-form-model.interface'
import userService from '../../../services/user.service'
import './shop-form.scss'
interface ShopFormProps {
	onSubmit: (values: any) => void
	shop?: any
}

const { Item } = Form
const ShopForm: React.FC<ShopFormProps> = ({ onSubmit, shop }) => {
	const [users, setUsers] = useState<any[]>([])
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [usersFetching, setUsersFetching] = useState(false)
	let creatorUser: any = null
	let timeoutId: any = null
	const handleSubmit = (values: ShopFormModel) => {
		let formValue: any = null
		values.location.coordinates[0] = +values.location.coordinates[0]
		values.location.coordinates[1] = +values.location.coordinates[1]
		values.location.lat = +values.location.lat
		values.location.lng = +values.location.lng
		values.location.lon = +values.location.lon
		if (values.location.address) {
			values.formatted_address = values.location.address
		}
		if (!shop) {
			values.creatorByUserEmail = creatorUser.email
			values.creatorByUserName = creatorUser.name
			values.scope = 'whappo-deactive'
			values.is_active = true
			formValue = {
				...values,
			}
		} else {
			formValue = {
				...shop,
				...values,
			}
		}
		onSubmit(formValue)
	}
	const fetchUser = (value: any) => {
		if (timeoutId) {
			clearInterval(timeoutId)
		}
		setSearchTerm(value)
		if (value.length >= 3) {
			setUsersFetching(true)
			timeoutId = setTimeout(() => {
				setUsers([])
				setUsersFetching(true)
				userService.getUsersByName(value).then((response) => {
					setUsers(response)
					setUsersFetching(false)
				})
			}, 500)
		} else {
			setUsers([])
			return
		}
	}
	return (
		<Form
			onFinish={(values) => handleSubmit(values as ShopFormModel)}
			wrapperCol={{ span: 18 }}
			labelCol={{ span: 4 }}
			style={{ fontWeight: 'bold' }}
			initialValues={!!shop ? shop : { location: { type: 'Point' } }}
		>
			<Item
				label="Shop name"
				name="name"
				rules={[{ required: true, message: 'Shop name is required' }]}
			>
				<Input placeholder="Shop name" />
			</Item>
			<Item label="Description" name="description">
				<Input placeholder="Shop description" />
			</Item>
			{(!shop || !shop._id) && (
				<Item
					label="Creator User"
					name="creatorByUserId"
					rules={[{ required: true, message: 'Creator user is required' }]}
				>
					<Select
						placeholder="Select user"
						notFoundContent={
							searchTerm.length < 3 ? (
								<span>Min. 3 characters for search</span>
							) : usersFetching ? (
								<Spin size="small" />
							) : (
								<span>Not found</span>
							)
						}
						filterOption={false}
						onSearch={fetchUser}
						onChange={(val) => {
							creatorUser = users.find((x) => x._id === val)
							console.log(creatorUser)
						}}
						showSearch
						style={{ width: '100%' }}
					>
						{users.map((user) => (
							<Select.Option key={user._id} value={user._id}>
								{user.name}
							</Select.Option>
						))}
					</Select>
				</Item>
			)}
			<Item
				label="Phone"
				name="phone"
				rules={[{ required: true, message: 'Shop phone is required' }]}
			>
				<Input placeholder="Shop phone" />
			</Item>
			<Item
				label="Rating"
				name="rating"
				rules={[{ required: true, message: 'Shop rating is required' }]}
			>
				<Input type="number" placeholder="Shop rating (4.5, 4.9)" />
			</Item>
			<Item
				label="Scope"
				name="scope"
				rules={[{ required: true, message: 'Scope is required' }]}
			>
				<Input type="text" placeholder="Scope (whappo for app)" />
			</Item>
			<Item label="Image Id" name="public_image_id">
				<Input placeholder="Image Id" />
			</Item>
			<Item label="Location" colon={false}>
				<Item
					labelCol={{ span: 4 }}
					label="Type"
					className="mt-4"
					name={['location', 'type']}
				>
					<Input placeholder="Type" />
				</Item>
				<Item
					labelCol={{ span: 4 }}
					label="Address"
					name={['location', 'address']}
					rules={[{ required: true, message: 'Shop address is required' }]}
				>
					<Input placeholder="Shop address" />
				</Item>
				<Item
					labelCol={{ span: 4 }}
					label="Lng"
					name={['location', 'lng']}
					rules={[{ required: true, message: 'Shop lng is required' }]}
				>
					<Input placeholder="Shop lng" type="number" />
				</Item>
				<Item
					labelCol={{ span: 4 }}
					label="Lat"
					name={['location', 'lat']}
					rules={[{ required: true, message: 'Shop lat is required' }]}
				>
					<Input placeholder="Shop lat" type="number" />
				</Item>
				<Item
					labelCol={{ span: 4 }}
					label="Lon"
					name={['location', 'lon']}
					rules={[{ required: true, message: 'Shop lon is required' }]}
				>
					<Input placeholder="Shop lon" type="number" />
				</Item>
				<Item
					labelCol={{ span: 4 }}
					label="Coordinate 0"
					name={['location', 'coordinates', 0]}
					rules={[{ required: true, message: 'Coordinate 0 is required' }]}
				>
					<Input placeholder="Coordinate 0" type="number" />
				</Item>
				<Item
					labelCol={{ span: 4 }}
					label="Coordinate 1"
					name={['location', 'coordinates', 1]}
					rules={[{ required: true, message: 'Coordinate 1 is required' }]}
				>
					<Input placeholder="Coordinate 1" type="number" />
				</Item>
			</Item>
			<Item label="Place Id" name="place_id">
				<Input placeholder="Place Id" />
			</Item>
			<Item label="Website" name="website">
				<Input placeholder="Website" />
			</Item>
			<Item label="Completed message" name="completed_message">
				<Input placeholder="Completed message" />
			</Item>
			<Item label="Introduction message" name="introduction_message">
				<Input placeholder="Introduction message" />
			</Item>
			<Item label="Hours (0, 23)">
				<Item
					labelCol={{ span: 4 }}
					label="Opening hour"
					name="opening_hour"
					rules={[{ required: true, message: 'Opening hour is required' }]}
				>
					<Input placeholder="Opening hour" type="number" />
				</Item>
				<Item
					labelCol={{ span: 4 }}
					label="Closing hour"
					name="closing_hour"
					rules={[{ required: true, message: 'Closing hour is required' }]}
				>
					<Input placeholder="Closing hour" type="number" />
				</Item>
			</Item>

			<Item>
				<Button htmlType="submit">Submit</Button>
			</Item>
		</Form>
	)
}
export default ShopForm
