import React from 'react'
import io from 'socket.io-client'
import { SOCKET_URL } from '../../config'
export const SocketContext = React.createContext<{
	socket: SocketIOClient.Socket | null
}>({ socket: null })

interface SocketProviderProps {}
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
	const socket = io.connect(SOCKET_URL)
	return (
		<SocketContext.Provider value={{ socket }}>
			{children}
		</SocketContext.Provider>
	)
}
export default SocketContext
