import { ArrowRightOutlined, ReloadOutlined, ShopOutlined } from '@ant-design/icons'
import { Button, Card, Spin, Statistic } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import reportService from '../../../services/report.service'
import './shop-request-count.scss'

interface Props {
	init: boolean
}

const ShopRequestCount: React.FC<Props> = ({ init }) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [shopRequestCount, setShopRequestCount] = useState<string>('');

	const getShopRequestCount = async () => {
		setLoading(true);
		const response = await reportService.getShopRequestCount();
		setShopRequestCount(response.shop_requests_count)
		setLoading(false)
	}

	useAsyncEffect(async () => {
		getShopRequestCount()
	}, [])

	return (
		<Card size="small" className="card-h140" title={
			<div style={{
				display: 'flex',
				justifyContent: 'space-between'
			}}>
				<span>Shop Requests</span>
				<div className="widget-actions">
					<Button onClick={getShopRequestCount} icon={<ReloadOutlined />} disabled={loading} type="primary" size="small" ghost />
					<Link to="/shop-requests">
						<Button icon={<ArrowRightOutlined />} type="primary" size="small" ghost />
					</Link>
				</div>
			</div>
		}>
			<div style={{ textAlign: 'center' }}>
				{
					!loading ? (
						<Statistic title={null} value={shopRequestCount} prefix={<ShopOutlined />} valueStyle={{ fontSize: '36px' }} />
					) : <Spin />
				}
			</div>
		</Card>
	)
}
export default ShopRequestCount
