import { BASE_URL, QFLOW_BASE_URL } from '../config';
import api from './api.service';

export default {
  getShopOrderReport: async (shopId: string, period: string, sortType: string, limit: number) => {
    const response = await api.get(QFLOW_BASE_URL + `/report/shop/${shopId}/orders/${period}/${sortType}/${limit}`);
    return response.data;
  },
  getPendingOrders: async (shopId: string, limit: number) => {
    const response = await api.get(QFLOW_BASE_URL + `/report/shop/${shopId}/orders/pending/${limit}`);
    return response.data;
  },
  getUserCount: async () => {
    const response = await api.get(BASE_URL + `/user/count`);
    return response.data;
  },
  getShopRequestCount: async () => {
    const response = await api.get(BASE_URL + `/shop/shopRequests/count`);
    return response.data;
  },
  getOrdersByLocation: async (period: any) => {
    const response = await api.get(QFLOW_BASE_URL + `/report/get-orders-by-location/${period}`);
    return response.data;
  }
};
