import { Select } from 'antd'
import React, { useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import shopService from '../../../../services/shop.service'
interface ShopSelectProps {
	onChange: (user: any) => void
}
const ShopSelect: React.FC<ShopSelectProps> = ({ onChange }) => {
	const [shops, setShops] = useState<any[]>([])
	useAsyncEffect(async () => {
		const response = await shopService.getAllShops()
		setShops(response)
	}, [])
	return (
		<Select
			placeholder="Select shop"
			notFoundContent={<span>Not found</span>}
			filterOption={false}
			onChange={(val) => {
				onChange(shops.find((x) => x._id === val))
			}}
			showSearch
			style={{ width: '100%' }}
			{...{
				filterOption: (input: any, option: any) =>
					option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
			}}
		>
			{shops.map((shop) => (
				<Select.Option key={shop._id} value={shop._id}>
					{shop.name}
				</Select.Option>
			))}
		</Select>
	)
}
export default ShopSelect
