import { Button, Form, Input } from 'antd'
import React from 'react'

interface UserFormProps {
	onSubmit: (values: any, add?: boolean) => void
	user?: any
}

const { Item } = Form
const UserForm: React.FC<UserFormProps> = ({ onSubmit, user }) => {
	const handleSubmit = (values: any) => {
		let formValue: any = null
		if (!user) {
			formValue = {
				...values,
			}
		} else {
			formValue = {
				...user,
				...values,
			}
		}
		onSubmit(formValue, !user)
	}
	return (
		<Form
			onFinish={(values) => handleSubmit(values as any)}
			wrapperCol={{ span: 18 }}
			labelCol={{ span: 4 }}
			style={{ fontWeight: 'bold' }}
			initialValues={!!user ? user : null}
		>
			<Item
				label="Full Name"
				name="name"
				rules={[{ required: true, message: 'Name is required' }]}
			>
				<Input placeholder="Name" />
			</Item>
			{!user && (
				<Item
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Password is required' }]}
				>
					<Input.Password placeholder="Password" />
				</Item>
			)}
			<Item
				label="First Name"
				name="firstname"
				rules={[{ required: true, message: 'First Name is required' }]}
			>
				<Input placeholder="First Name" />
			</Item>
			<Item
				label="Last Name"
				name="lastname"
				rules={[{ required: true, message: 'Last Name is required' }]}
			>
				<Input placeholder="Phone" />
			</Item>
			<Item label="Email" name="email">
				<Input placeholder="Email" />
			</Item>
			<Item label="Phone" name="phone">
				<Input placeholder="Phone" />
			</Item>
			<Item>
				<Button htmlType="submit">Submit</Button>
			</Item>
		</Form>
	)
}
export default UserForm
