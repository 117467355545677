import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import shopService from '../../../services/shop.service'
import { LayoutContext } from '../../providers/layout'
import ShopTable from '../../tables/shop-table'

interface ShopsProps {}
const Shops: React.FC<ShopsProps> = () => {
	const { setTitle, setLoading } = useContext(LayoutContext)
	const [shops, setShops] = useState<any[]>([])
	const [reload, setReload] = useState(false)
	useEffect(() => {
		setTitle('Shops')
	})
	useAsyncEffect(async () => {
		setLoading(true)
		const whappoScopedShops = await shopService.getAllShops()
		setShops(whappoScopedShops)
		setLoading(false)
	}, [reload])
	const updateShopActive = async (shopId: string, status: boolean) => {
		await shopService.updateShopActiveStatus(shopId, status)
		setReload((prev) => !prev)
	}
	return (
		<>
			<div className="text-right">
				<Link to="/shops/new">
					<Button className="mb-16" type="primary" icon={<PlusOutlined />}>
						Add new shop
					</Button>
				</Link>
			</div>
			<ShopTable data={shops} updateShopActive={updateShopActive} />
		</>
	)
}
export default Shops
