import { Col, Row } from 'antd'
import React from 'react'
import MessageInput from '../message-input/message-input'
import MessageList from '../message-list/message-list'
interface MessageListWithInputProps {
	messagesRef: React.RefObject<HTMLDivElement>
	messages: any[]
	supportShop: any
	onSend: (value: string) => void
	height: string | number
}
const MessageListWithInput: React.FC<MessageListWithInputProps> = ({
	messagesRef,
	messages,
	supportShop,
	onSend,
	height,
}) => {
	return (
		<Col xs={24} lg={16} style={{ padding: 16 }}>
			<Row style={{ width: '100%', height: '100%' }}>
				<Col span={24}>
					<div
						ref={messagesRef}
						style={{ height, overflowY: 'auto' }}
						className="custom-scrollbar"
					>
						<MessageList messages={messages} senderId={supportShop._id} />
					</div>
				</Col>
				<Col span={24}>
					<MessageInput onSend={onSend} />
				</Col>
			</Row>
		</Col>
	)
}
export default MessageListWithInput
