import { Button, Col, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import { Order } from '../../../interfaces/order.interface'
import chatService from '../../../services/chat.service'
import orderService from '../../../services/order.service'
import MessageList from '../../shared/message-list/message-list'
import OrderTable from '../../tables/order-table'
import ShopSelect from './components/shop-select'
import UserSelect from './components/user-select'
interface ConversationsProps {}
const Conversations: React.FC<ConversationsProps> = () => {
	const [selectedShop, setSelectedShop] = useState<any>(null)
	const [selectedUser, setSelectedUser] = useState<any>(null)
	const [orders, setOrders] = useState<Order[] | null>(null)
	const [messages, setMessages] = useState<any[] | null>(null)
	const [search, setSearch] = useState(false)
	const chatContainerRef = useRef<HTMLDivElement>(null)
	const submit = () => {
		setSearch((prev) => !prev)
	}
	useEffect(() => {
		let timeout: number | null = null
		if (chatContainerRef && chatContainerRef.current) {
			timeout = window.setTimeout(() => {
				chatContainerRef.current!.scrollBy({ top: 10000, behavior: 'smooth' })
			}, 250)
		}
		return () => {
			if (timeout) {
				clearTimeout(timeout)
			}
		}
	}, [messages])
	useAsyncEffect(async () => {
		if (selectedUser && selectedShop) {
			const ordersResponse = await orderService.getOrdersByUserIdAndShopId(
				selectedUser._id,
				selectedShop._id
			)
			setOrders(ordersResponse)
			const lastChat = await chatService.getLastChatBetweenUsers(
				selectedShop._id,
				selectedUser._id
			)
			if (lastChat && lastChat.chatid) {
				const messagesResponse = await chatService.getChatHistory(
					selectedShop._id,
					selectedUser._id,
					null
				)
				setMessages(
					messagesResponse.sort(
						(a: any, b: any) =>
							new Date(a.date).getTime() - new Date(b.date).getTime()
					)
				)
			} else {
				setMessages([])
			}
		}
	}, [search])
	return (
		<div>
			<Row gutter={16}>
				<Col xs={24} lg={10}>
					<UserSelect onChange={setSelectedUser}></UserSelect>
				</Col>
				<Col xs={24} lg={12}>
					<ShopSelect onChange={setSelectedShop}></ShopSelect>
				</Col>
				<Col xs={24} lg={2}>
					<Button type="primary" onClick={submit}>
						Search
					</Button>
				</Col>
			</Row>
			{orders && messages && (
				<Row gutter={16} style={{ marginTop: 32 }}>
					<Col xs={24} lg={10}>
						<div
							className="custom-scrollbar"
							ref={chatContainerRef}
							style={{
								maxHeight: 600,
								overflowX: 'hidden',
								overflowY: 'scroll',
								padding: 16,
							}}
						>
							<MessageList senderId={selectedShop._id} messages={messages} />
						</div>
					</Col>
					<Col xs={24} lg={14}>
						<OrderTable clientSidePagination orders={orders} />
					</Col>
				</Row>
			)}
		</div>
	)
}
export default Conversations
