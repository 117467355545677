import { Col, Row } from 'antd'
import React from 'react'
import OrdersByLocation from './components/orders-by-location-chart'
import ShopOrders from './components/shop-orders-chart'
interface HomeChartsProps {}
const HomeCharts: React.FC<HomeChartsProps> = () => {
	return (
		<Row gutter={16} style={{ marginBottom: 30 }}>
			<Col span={12}>
				<OrdersByLocation />
			</Col>
			<Col span={12}>
				<ShopOrders />
			</Col>
		</Row>
	)
}
export default HomeCharts
