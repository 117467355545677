import {
	EllipsisOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
} from '@ant-design/icons'
import { Dropdown, Layout, Menu, Spin } from 'antd'
import React, { useContext, useState } from 'react'
import logo from '../../../static/img/whappo-logo.png'
import { AuthContext } from '../../providers/auth'
import { LayoutContext } from '../../providers/layout'
import MainMenu from './components/main-menu/main-menu'
import './main-layout.scss'
const { Sider, Header, Content } = Layout

interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
	const { title, loading } = useContext(LayoutContext)
	const { logout, user } = useContext(AuthContext)
	const [collapsed, setCollapsed] = useState(false)
	const userMenu = (
		<Menu>
			<Menu.Item key="0">Profile</Menu.Item>
			<Menu.Item key="1" onClick={logout}>
				Logout
			</Menu.Item>
		</Menu>
	)
	return (
		<Spin spinning={loading ? loading : false}>
			<Layout className="mh-100vh">
				<Sider
					trigger={null}
					collapsible={true}
					breakpoint="lg"
					collapsed={collapsed}
					onCollapse={(collapsed, type) => setCollapsed(collapsed)}
					className={collapsed ? '' : 'sider-wrapper'}
				>
					<div
						className="logo"
						style={{
							paddingLeft: collapsed ? 0 : undefined,
							paddingRight: collapsed ? 0 : undefined,
						}}
					>
						<img src={logo} />
					</div>
					<MainMenu />
				</Sider>
				<Layout className="site-layout">
					<Header className="site-layout-background header">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div className="collapse-icons">
								{collapsed ? (
									<MenuUnfoldOutlined
										className="mr-16"
										onClick={() => setCollapsed(false)}
									/>
								) : (
									<MenuFoldOutlined
										className="mr-16"
										onClick={() => setCollapsed(true)}
									/>
								)}
							</div>
							<div style={{ flex: 1 }}>{title}</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<span className="fs-14">{user?.name}</span>
								<Dropdown overlay={userMenu} trigger={['click']}>
									<EllipsisOutlined
										style={{ fontSize: 24, marginLeft: 4 }}
										rotate={90}
									/>
								</Dropdown>
							</div>
						</div>
					</Header>
					<Content
						className="site-layout-background"
						style={{
							margin: '24px 16px',
							padding: 24,
							minHeight: 280,
						}}
					>
						{children}
					</Content>
				</Layout>
			</Layout>
		</Spin>
	)
}
export default MainLayout
