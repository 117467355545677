import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Collapse } from 'antd'
import React from 'react'
import ConversationList from '../conversation-list/conversation-list'
interface ConversationListWithSearchProps {
	supportShopId: any
	chats: any[]
	selectedChatId: any
	onConversationClick: (conversation: any) => void
	onNewChatClick: () => void
}
const ConversationListWithSearch: React.FC<ConversationListWithSearchProps> = ({
	supportShopId,
	chats,
	selectedChatId,
	onConversationClick,
	onNewChatClick,
}) => {
	return (
		<Collapse
			bordered={false}
			defaultActiveKey={['1']}
			expandIcon={({ isActive }) => (
				<CaretRightOutlined rotate={isActive ? 90 : 0} />
			)}
			className="site-collapse-custom-collapse"
		>
			<Collapse.Panel
				style={{ maxHeight: '700px', overflowY: 'auto' }}
				key="1"
				header={
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<span>Collapse Chats</span>
						<Button
							onClick={(e) => {
								e.stopPropagation()
								onNewChatClick()
							}}
							icon={<PlusOutlined />}
						>
							New Chat
						</Button>
					</div>
				}
				className="site-collapse-custom-panel custom-scrollbar"
			>
				<ConversationList
					supportShopId={supportShopId}
					chats={chats}
					selectedChatId={selectedChatId}
					onConversationClick={onConversationClick}
					search
				/>
			</Collapse.Panel>
		</Collapse>
	)
}
export default ConversationListWithSearch
