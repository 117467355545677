import { Col, Row } from 'antd'
import React, { useContext, useEffect } from 'react'
import logo from '../../../static/img/whappo-logo.png'
import LoginForm from '../../forms/login-form/login-form'
import { AuthContext } from '../../providers/auth'
import { LayoutContext } from '../../providers/layout'
import './login.scss'
interface LoginProps {}
const Login: React.FC<LoginProps> = () => {
	const { login } = useContext(AuthContext)
	const { setTitle } = useContext(LayoutContext)
	useEffect(() => {
		setTitle('Login')
	}, [])
	return (
		<Row
			className="mh-100vh login-container"
			justify="center"
			align="middle"
			style={{ alignContent: 'center' }}
		>
			<Col xs={22} sm={22} md={11} lg={6} xl={6}>
				<img src={logo} />
			</Col>
			<Col xs={22} sm={22} md={11} lg={6} xl={6}>
				<LoginForm
					onSubmit={(values) => login(values.username, values.password)}
				/>
			</Col>
		</Row>
	)
}
export default Login
