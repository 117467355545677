import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import React from 'react'
import './login-form.scss'

interface LoginFormModel {
	username: string
	password: string
}

interface LoginFormProps {
	onSubmit: (values: LoginFormModel) => void
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
	return (
		<>
			<Form className="login-form" onFinish={(values: any) => onSubmit(values)}>
				<Form.Item
					name="username"
					rules={[{ required: true, message: 'Please input your Username!' }]}
				>
					<Input
						size="large"
						prefix={<UserOutlined className="site-form-item-icon" />}
						placeholder="Username"
					/>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[{ required: true, message: 'Please input your Password!' }]}
				>
					<Input
						size="large"
						prefix={<LockOutlined className="site-form-item-icon" />}
						type="password"
						placeholder="Password"
					/>
				</Form.Item>

				<Form.Item>
					<Button
						block
						type="primary"
						htmlType="submit"
						className="login-form-button"
					>
						Log in
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}
export default LoginForm
