import React, { useLayoutEffect, useState } from 'react'

export const LayoutContext = React.createContext<{
	title: string
	setTitle: React.Dispatch<React.SetStateAction<string>>
	loading: boolean
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
}>({
	title: null,
	setTitle: null,
	loading: null,
	setLoading: null
} as any)

interface AuthProviderProps {}

export const LayoutProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [title, setTitle] = useState<string>('Test title')
	useLayoutEffect(() => {
		document.title = title + ' | Whappo Dashboard'
	}, [title])
	const [loading, setLoading] = useState(false)
	return (
		<LayoutContext.Provider
			value={{
				title,
				setTitle,
				loading,
				setLoading
			}}
		>
			{children}
		</LayoutContext.Provider>
	)
}
