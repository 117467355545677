import {
	CheckOutlined,
	CopyTwoTone,
	EditTwoTone,
	QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, message, Popconfirm, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
interface ApproveShopsTableProps {
	shops: any[]
	onShopApprove: (shopId: string) => void
}
const ApproveShopsTable: React.FC<ApproveShopsTableProps> = ({
	shops,
	onShopApprove,
}) => {
	return (
		<Table dataSource={shops} rowKey="_id">
			<Column
				ellipsis
				title="Id"
				dataIndex="_id"
				key="_id"
				width={250}
				render={(id) => (
					<div>
						<CopyToClipboard
							onCopy={() => message.success('Copied', 0.4)}
							text={id}
						>
							<CopyTwoTone className="mr-8" />
						</CopyToClipboard>
						{id}
					</div>
				)}
			></Column>
			<Column ellipsis title="Shop Name" dataIndex="name" key="name"></Column>
			<Column
				title=""
				key="actions"
				align="center"
				width={250}
				render={(text, record: any) => (
					<div style={{ textAlign: 'right' }}>
						<Link to={'/shops/edit/' + record._id}>
							<EditTwoTone className="mr-16 fs-18" />
						</Link>
						<Popconfirm
							title="Are you sure want to approve this shop？"
							icon={<QuestionCircleOutlined />}
							onConfirm={() => onShopApprove(record._id)}
						>
							<Button type="primary" icon={<CheckOutlined />}>
								Approve shop
							</Button>
						</Popconfirm>
					</div>
				)}
			/>
		</Table>
	)
}
export default ApproveShopsTable
