import { Card, Radio } from 'antd'
import React, { useContext, useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import reportService from '../../../../../../services/report.service'
import { LayoutContext } from '../../../../../providers/layout'
import PieChart from '../../../../../shared/pie-chart/pie-chart'
interface OrdersByLocationProps {}
const OrdersByLocation: React.FC<OrdersByLocationProps> = () => {
	const { setLoading } = useContext(LayoutContext)
	const [data, setData] = useState<any[]>([])
	const [period, setPeriod] = useState<string>('30')
	useAsyncEffect(async () => {
		setLoading(true)
		const response = await reportService.getShopOrderReport(
			'all',
			period,
			'ASC',
			5
		)
		const locationResponse = await reportService.getOrdersByLocation(period)
		setData(locationResponse)
		setLoading(false)
	}, [period])
	const handlePeriodChange = (event: any) => {
		setPeriod(event.target.value)
	}
	return (
		<Card size="small">
			<h1 style={{ padding: '4px 16px' }}>Orders by location</h1>
			<div style={{ padding: '4px 16px' }} className="actions">
				<Radio.Group
					defaultValue={period}
					buttonStyle="solid"
					size="small"
					onChange={handlePeriodChange}
				>
					<Radio.Button value="1">Day</Radio.Button>
					<Radio.Button value="7">Week</Radio.Button>
					<Radio.Button value="30">Month</Radio.Button>
					<Radio.Button value="all">All</Radio.Button>
				</Radio.Group>
			</div>
			<PieChart data={data} />
		</Card>
	)
}
export default OrdersByLocation
