import { ArrowRightOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Spin, Statistic } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import reportService from '../../../services/report.service'
import './user-count.scss'

interface Props {
	init: boolean,
	autoReload: number
}

const UserCount: React.FC<Props> = ({ init, autoReload }) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [userCount, setUserCount] = useState<string>('');
	const [reloadInterval, setReloadInterval] = useState<any>(null);

	const getUserCount = async () => {
		setLoading(true);
		const response = await reportService.getUserCount();
		setUserCount(response.user_count)
		setLoading(false)
	}

	useAsyncEffect(async () => {
		if (autoReload) {
			setReloadInterval(clearInterval(reloadInterval));
			setReloadInterval(setInterval(() => {
				getUserCount();
			}, autoReload * 1000));
			return () => setReloadInterval(clearInterval(reloadInterval));
		} else {
			setReloadInterval(clearInterval(reloadInterval));
		}
	}, [autoReload])

	useAsyncEffect(async () => {
		getUserCount()
	}, [])

	return (
		<Card size="small" className="card-h140" title={
			<div style={{
				display: 'flex',
				justifyContent: 'space-between'
			}}>
				<span>Users</span>
				<div className="widget-actions">
					<Button onClick={getUserCount} icon={<ReloadOutlined />} disabled={loading} type="primary" size="small" ghost />
					<Link to="/users">
						<Button icon={<ArrowRightOutlined />} type="primary" size="small" ghost />
					</Link>
				</div>
			</div>
		}>
			<div style={{ textAlign: 'center' }}>
				{
					!loading ? (
						<Statistic title={null} value={userCount} prefix={<UserOutlined />} valueStyle={{ fontSize: '36px' }} />
					) : <Spin />
				}
			</div>
		</Card>
	)
}
export default UserCount
