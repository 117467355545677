import { Select, Spin } from 'antd'
import React, { useState } from 'react'
import userService from '../../../../services/user.service'
interface UserSelectProps {
	onChange: (user: any) => void
}
const UserSelect: React.FC<UserSelectProps> = ({ onChange }) => {
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [usersFetching, setUsersFetching] = useState(false)
	const [users, setUsers] = useState<any[]>([])
	let timeoutId: any = null
	const fetchUser = (value: any) => {
		if (timeoutId) {
			clearInterval(timeoutId)
		}
		setSearchTerm(value)
		if (value.length >= 3) {
			setUsersFetching(true)
			timeoutId = setTimeout(() => {
				setUsers([])
				setUsersFetching(true)
				userService.getUsersByName(value).then((response) => {
					setUsers(response)
					setUsersFetching(false)
				})
			}, 500)
		} else {
			setUsers([])
			return
		}
	}
	return (
		<Select
			placeholder="Select user"
			notFoundContent={
				searchTerm.length < 3 ? (
					<span>Min. 3 characters for search</span>
				) : usersFetching ? (
					<Spin size="small" />
				) : (
					<span>Not found</span>
				)
			}
			filterOption={false}
			onSearch={fetchUser}
			onChange={(val) => {
				onChange(users.find((x) => x._id === val))
			}}
			showSearch
			style={{ width: '100%' }}
		>
			{users.map((user) => (
				<Select.Option key={user._id} value={user._id}>
					{user.name}
				</Select.Option>
			))}
		</Select>
	)
}
export default UserSelect
