import React from 'react'
import {
	Cell,
	Pie,
	PieChart as RechartPie,
	ResponsiveContainer,
	Tooltip,
} from 'recharts'
import { randomColor } from '../../../helpers/random-color'
interface PieChartProps {
	data: any
}
const PieChart: React.FC<PieChartProps> = ({ data }) => {
	return (
		<ResponsiveContainer minHeight={300}>
			<RechartPie>
				{/* <Pie
					nameKey="location"
					data={data}
					fill="#8884d8"
					dataKey="count"
					label
				>
					{data.map((entry: any, index: any) => (
						<Cell key={`cell-${index}`} fill={'#525252'} />
					))}
				</Pie> */}
				<Pie
					nameKey="location"
					label={(entry) => entry.location + ' (' + entry.count + ')'}
					data={data}
					fill="#8884d8"
					dataKey="count"
				>
					{data.map((entry: any, index: any) => (
						<Cell key={`cell-${index}`} fill={randomColor()} />
					))}
				</Pie>
				<Tooltip />
			</RechartPie>
		</ResponsiveContainer>
	)
}
export default PieChart
