import { Avatar, Input, List } from 'antd'
import React, { useEffect, useState } from 'react'
import pp from '../../../static/img/profile-photo.png'
interface ConversationListProps {
	chats: any[]
	onConversationClick: (conversation: any) => void
	supportShopId: any
	selectedChatId: any
	search: boolean
}
const ConversationList: React.FC<ConversationListProps> = ({
	chats,
	onConversationClick,
	supportShopId,
	selectedChatId,
}) => {
	const [filteredChats, setFilteredChats] = useState(chats)
	const [searchValue, setSearchValue] = useState('')
	const getName = (chat: any): string => {
		return supportShopId === chat.sender._id
			? chat.receiver.name
			: chat.sender.name
	}
	useEffect(() => {
		if (!searchValue) {
			setFilteredChats(chats)
		} else {
			setFilteredChats((prev: any) => {
				return prev.filter((p: any) => {
					return getName(p).toLowerCase().includes(searchValue.toLowerCase())
				})
			})
		}
	}, [searchValue, chats])

	return (
		<>
			<div
				style={{
					marginLeft: -12,
					marginRight: -12,
					marginBottom: 8,
					marginTop: 8,
				}}
			>
				<Input.Search
					onChange={(e) => setSearchValue(e.target.value)}
					placeholder="Search users..."
				></Input.Search>
			</div>
			<List
				itemLayout="horizontal"
				dataSource={filteredChats}
				className="custom-content"
				renderItem={(item: any) => (
					<List.Item
						style={{ cursor: 'pointer' }}
						onClick={() => onConversationClick(item)}
						className={selectedChatId === item._id ? 'selected-chat' : ''}
					>
						<List.Item.Meta
							style={{ overflow: 'hidden' }}
							avatar={<Avatar src={pp} />}
							title={getName(item)}
							description={
								<span className="one-line-text">{item.message.body}</span>
							}
						/>
					</List.Item>
				)}
			/>
		</>
	)
}
export default ConversationList
