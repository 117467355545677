// tslint:disable: variable-name


export class SocketUser {
    _id: string | null = null; // id
    shopid: string | null = null;
    name?: string | null = null; // name
    avatar_url?: string | null = null; // avatar image url
    tid?: string | null = null;
    isBot?: string | null = null;
}

export class SocketMessage {
    body: string | null = null; // body
}

export class SocketIOMessage {
    _id: string | null = null; // id given at database
    chatid: string | null = null; // room id or session id between sender and receiver
    type = 'tm';
    sender: SocketUser = new SocketUser();
    receiver: SocketUser = new SocketUser();
    message: SocketMessage = new SocketMessage();
    date: string | null = null;
    read: string | null = null;
    read_date: string | null = null;
    action: string | null = null;

    constructor(
        msg: any
    ) {
        this.type = msg.t;
        this.sender._id = msg.s.id;
        this.sender.name = msg.s.name;
        this.sender.shopid = msg.s.uid;
        this.sender.tid = msg.s.tid;
        this.sender.isBot = msg.s.isBot;
        this.receiver._id = msg.r.id;
        this.receiver.name = msg.r.name;
        this.message.body = msg.m.b;
        this.sender.avatar_url = '';
        this.receiver.avatar_url = '';
        this.chatid = msg.c;
        this._id = msg.i;
        this.action = msg.a;
        this.date = msg.d;
    }
}

