import { BASE_URL } from '../config';
import api from './api.service';
export default {
    getAppVersion: async (): Promise<{ _id: string, android: string[], ios: string[] }> => {
        const response = await api.get(BASE_URL + '/appVersions');
        return response.data;
    },
    updateAppVersion: async (body: any): Promise<{ success: boolean }> => {
        const response = await api.post(BASE_URL + '/appVersions', body);
        return response.data;
    }
}