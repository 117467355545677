import { SendOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React, { useState } from 'react'
interface MessageInputProps {
	onSend: (value: string) => void
}
const MessageInput: React.FC<MessageInputProps> = ({ onSend }) => {
	const [message, setMessage] = useState('')
	return (
		<Input.Search
			size="large"
			value={message}
			onChange={(e) => setMessage(e.target.value)}
			enterButton={<SendOutlined />}
			onSearch={(value) => {
				setMessage('')
				onSend(value)
			}}
		/>
	)
}
export default MessageInput
