import ApproveShops from './components/containers/approve-shops/approve-shops'
import BulkNotification from './components/containers/bulk-notification/bulk-notification'
import Config from './components/containers/config/config'
import Conversations from './components/containers/conversations/conversations'
import Home from './components/containers/home/home'
import Orders from './components/containers/orders/orders'
import Reports from './components/containers/reports/reports'
import ShopOrders from './components/containers/shop-orders/shop-orders'
import ShopRequests from './components/containers/shop-requests/shop-requests'
import Shops from './components/containers/shops/shops'
import Support from './components/containers/support/support'
import UpsertShop from './components/containers/upsert-shop/upsert-shop'
import UpsertUser from './components/containers/upsert-user/upsert-user'
import Users from './components/containers/users/users'

export const appRoutes = [
	{
		path: '/',
		component: Home,
		exact: true,
	},
	{
		path: '/shops',
		component: Shops,
		exact: true,
	},
	{
		path: '/shops/new',
		component: UpsertShop,
		exact: true,
	},
	{
		path: '/shop-requests',
		component: ShopRequests,
		exact: true,
	},
	{
		path: '/shops/edit/:shopId',
		component: UpsertShop,
		exact: true,
	},
	{
		path: '/shops/orders/:shopId',
		component: ShopOrders,
		exact: true,
	},
	{
		path: '/approve-shops',
		component: ApproveShops,
		exact: true,
	},
	{
		path: '/config',
		component: Config,
		exact: true,
	},
	{
		path: '/users',
		component: Users,
		exact: true,
	},
	{
		path: '/users/edit/:userId',
		component: UpsertUser,
		exact: true,
	},
	{
		path: '/users/new',
		component: UpsertUser,
		exact: true,
	},
	{
		path: '/bulk-notification',
		component: BulkNotification,
		exact: true,
	},
	{
		path: '/bulk-notification/:userId',
		component: BulkNotification,
		exact: true,
	},
	{
		path: '/conversations',
		component: Conversations,
		exact: true,
	},
	{
		path: '/support',
		component: Support,
		exact: true,
	},
	{
		path: '/orders',
		component: Orders,
		exact: true,
	},
	{
		path: '/reports',
		component: Reports,
		exact: true,
	},
]
