import React from 'react'
import {
	Bar,
	BarChart as RechartBar,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Text,
	Tooltip,
	XAxis,
} from 'recharts'
interface BarChartProps {
	data: any
	bars: { dataKey: string; fill: string }[]
	nameKey: string
}
{
}
const CustomizedAxisTick = ({ x, y, payload }: any) => (
	<Text x={x} y={y} fontSize="12" textAnchor="middle" verticalAnchor="start">
		{payload.value.length > 10
			? payload.value.slice(0, 10) + '...'
			: payload.value}
	</Text>
)

const BarChart: React.FC<BarChartProps> = ({ data, bars, nameKey }) => {
	return (
		<ResponsiveContainer minHeight={300}>
			<RechartBar
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis interval={0} dataKey={nameKey} tick={<CustomizedAxisTick />} />
				<Tooltip />
				<Legend />
				{bars.map((bar) => (
					<Bar
						dataKey={bar.dataKey}
						key={bar.dataKey}
						fill={bar.fill}
						isAnimationActive={false}
					/>
				))}
			</RechartBar>
		</ResponsiveContainer>
	)
}
export default BarChart
