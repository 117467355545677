import { BASE_URL } from '../config';
import { ShopRequest } from '../interfaces/shop-request.interface';
import api from './api.service';


export default {
    getAllShops: async () => {
        const response = await api.get(BASE_URL + '/shop/getAllShops');
        return response.data;
    },
    getAllWhappoDeactiveScopedShops: async () => {
        const response = await api.get(BASE_URL + '/shop/getAllWhappoDeactiveScopedShops');
        return response.data;
    },
    getShopById: async (shopId: string) => {
        const response = await api.get(BASE_URL + '/shop/getShopById?shopId=' + shopId);
        return response.data;
    },
    upsertShop: async (body: any) => {
        const response = await api.post(BASE_URL + '/shop/createNewShop', body);
        return response.data;
    },
    updateShopActiveStatus: async (shopId: string, status: boolean) => {
        const response = await api.post(BASE_URL + '/shop/updateShopActiveStatus', { shopId, status });
        return response.data;
    },
    approveShop: async (shopId: string): Promise<{ success: boolean }> => {
        const response = await api.post(BASE_URL + '/shop/approveShop', { shopId });
        return response.data;
    },
    getShopRequests: async (): Promise<ShopRequest[]> => {
        const response = await api.get(BASE_URL + '/shop/shopRequests');
        return response.data;
    },
    getShopRequest: async (id: string): Promise<ShopRequest> => {
        const response = await api.get(BASE_URL + '/shop/shopRequest/' + id);
        return response.data;
    },
    completeShopRequest: async (id: string): Promise<{ success: boolean }> => {
        const response = await api.post(BASE_URL + '/shop/completeShopRequest', { id });
        return response.data;
    },
    getWhappoSupportShop: async (): Promise<any> => {
        const response = await api.post(BASE_URL + '/shop/getWhappoSupportShop', null);
        return response.data;
    }
}