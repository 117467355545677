import { message } from 'antd'
import React, { useContext, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import userService from '../../../services/user.service'
import UserForm from '../../forms/user-form/user-form'
import { LayoutContext } from '../../providers/layout'

interface UpsertUserProps {
	history?: any
}
const UpsertUser: React.FC<UpsertUserProps> = ({ history }) => {
	const { setLoading, loading, setTitle } = useContext(LayoutContext)
	const { userId } = useParams()
	const [user, setUser] = useState<any>(null)
	useAsyncEffect(async () => {
		if (userId) {
			setLoading(true)
			const response = await userService.getUserById(userId!)
			console.log(response)
			setTitle('Edit user ' + response.name)
			setUser(response)
			setLoading(false)
		} else {
			setTitle('Add new user')
		}
	}, [])
	return !loading ? (
		<UserForm
			onSubmit={async (values, add) => {
				setLoading(true)
				if (add) {
					const response = await userService.addNewUser(values)
					if (response) {
						message.success('Success')
					}
					setLoading(false)
				} else {
					const response = await userService.updateUser(values)
					console.log(response)
					if (response) {
						message.success('Success')
					}
					setLoading(false)
				}
				history.push('/users')
			}}
			user={user}
		/>
	) : null
}
export default withRouter(UpsertUser)
