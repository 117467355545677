import { message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import shopService from '../../../services/shop.service'
import { LayoutContext } from '../../providers/layout'
import ApproveShopsTable from '../../tables/approve-shops-table'
interface ApproveShopsProps {}
const ApproveShops: React.FC<ApproveShopsProps> = () => {
	const { setTitle, setLoading } = useContext(LayoutContext)
	const [shops, setShops] = useState<any[]>([])
	const [reload, setReload] = useState(false)
	useEffect(() => {
		setTitle('Approve Shops')
	})
	useAsyncEffect(async () => {
		setLoading(true)
		const response = await shopService.getAllWhappoDeactiveScopedShops()
		setShops(response)
		setLoading(false)
	}, [reload])

	const approveShop = async (shopId: string) => {
		setLoading(true)
		const response = await shopService.approveShop(shopId)
		setLoading(false)
		if (response.success) {
			message.success('Success')
			setReload((prev) => !prev)
		} else {
			message.success("Error, can't approve shop")
		}
	}
	return <ApproveShopsTable shops={shops} onShopApprove={approveShop} />
}
export default ApproveShops
