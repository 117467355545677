import React, { useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import { ShopRequest } from '../../../interfaces/shop-request.interface'
import shopService from '../../../services/shop.service'
import ShopRequestTable from '../../tables/shop-request-table'
interface ShopRequestsProps {}
const ShopRequests: React.FC<ShopRequestsProps> = () => {
	const [shopRequests, setShopRequests] = useState<ShopRequest[]>([])
	useAsyncEffect(async () => {
		const requests = await shopService.getShopRequests()
		setShopRequests(requests)
	}, [])
	return <ShopRequestTable shopRequests={shopRequests} />
}
export default ShopRequests
