import {
	CheckOutlined,
	CloseOutlined,
	CopyTwoTone,
	EditTwoTone,
	OrderedListOutlined,
} from '@ant-design/icons'
import { Descriptions, message, Switch, Table } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
interface ShopTableProps {
	data: any[]
	updateShopActive: (shopId: string, status: boolean) => void
}
interface ExpandedRowProps {
	name: string
	_id: string
	opening_hour: string
	closing_hour: string
	isActive: boolean
}

const { Column } = Table

const ExpandedRow: React.FC<ExpandedRowProps> = ({
	name,
	_id,
	opening_hour,
	closing_hour,
	isActive,
}) => {
	return (
		<Descriptions layout="horizontal">
			<Descriptions.Item label="Id">{_id}</Descriptions.Item>
			<Descriptions.Item label="Name">{name}</Descriptions.Item>
			<Descriptions.Item label="Hours">
				{opening_hour}:00-{closing_hour}:00
			</Descriptions.Item>
			<Descriptions.Item label="Active">
				{isActive ? (
					<CheckOutlined style={{ color: 'green' }} />
				) : (
					<CloseOutlined style={{ color: 'red' }} />
				)}
			</Descriptions.Item>
		</Descriptions>
	)
}

const ShopTable: React.FC<ShopTableProps> = ({ data, updateShopActive }) => {
	const { md } = useBreakpoint()
	return (
		<Table
			dataSource={data}
			rowKey="_id"
			pagination={false}
			expandable={
				!md
					? {
							expandedRowRender: (record: any) => <ExpandedRow {...record} />,
					  }
					: undefined
			}
		>
			{md && (
				<Column
					ellipsis
					title="Id"
					dataIndex="_id"
					key="_id"
					render={(id) => (
						<div>
							<CopyToClipboard
								onCopy={() => message.success('Copied', 0.4)}
								text={id}
							>
								<CopyTwoTone className="mr-8" />
							</CopyToClipboard>
							{id}
						</div>
					)}
				></Column>
			)}
			<Column ellipsis title="Shop Name" dataIndex="name" key="name"></Column>
			{md && (
				<Column
					ellipsis
					title="Hours"
					key="hours"
					width={120}
					render={(_, record: any) => (
						<span>
							{record.opening_hour}:00-{record.closing_hour}:00
						</span>
					)}
				></Column>
			)}
			{md && (
				<Column
					ellipsis
					title="Active"
					key="isActive"
					width={100}
					dataIndex="isActive"
					align="center"
					render={(isActive, record: any) => {
						return (
							<Switch
								checked={isActive}
								onChange={(checked) => updateShopActive(record._id, checked)}
							></Switch>
						)
						// return isActive ? (
						// 	<CheckOutlined style={{ color: 'green' }} />
						// ) : (
						// 	<CloseOutlined style={{ color: 'red' }} />
						// )
					}}
				></Column>
			)}
			<Column
				ellipsis
				title=""
				key="actions"
				align="center"
				width={100}
				render={(text, record: any) => (
					<>
						<Link to={'/shops/edit/' + record._id}>
							<EditTwoTone className="mr-8 fs-18" />
						</Link>
						<Link to={'/shops/orders/' + record._id}>
							<OrderedListOutlined className="fs-18" />
						</Link>
					</>
				)}
			/>
		</Table>
	)
}
export default ShopTable
