import { Button, Select } from 'antd'
import React from 'react'
interface AppVersionProps {
	appVersion: { _id: string; android: string[]; ios: string[] }
	onVersionChange: (value: string[], platform: 'ios' | 'android') => void
	save: () => void
}
const AppVersion: React.FC<AppVersionProps> = ({
	appVersion,
	onVersionChange,
	save,
}) => {
	const handleChange = (value: string[], platform: 'ios' | 'android') => {
		onVersionChange(value, platform)
	}
	return (
		!!appVersion && (
			<div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						marginBottom: 16,
					}}
				>
					<span style={{ display: 'inline-block', width: 100 }}>Android: </span>
					<Select
						mode="tags"
						style={{ width: '100%' }}
						placeholder="Android versions"
						onChange={(val) => handleChange(val, 'android')}
						notFoundContent={null}
						defaultValue={appVersion.android as any}
					></Select>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						marginBottom: 16,
					}}
				>
					<span style={{ display: 'inline-block', width: 100 }}>IOS: </span>
					<Select
						mode="tags"
						style={{ width: '100%' }}
						placeholder="IOS versions"
						onChange={(val) => handleChange(val, 'ios')}
						notFoundContent={null}
						defaultValue={appVersion.ios as any}
					></Select>
				</div>
				<div style={{ float: 'right' }}>
					<Button type="primary" onClick={save}>
						Save
					</Button>
				</div>
			</div>
		)
	)
}
export default AppVersion
