import { ArrowRightOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row, Slider } from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
import React, { useContext, useEffect, useState } from 'react'
import { LayoutContext } from '../../providers/layout'
import PendingOrders from '../pending-orders/pending-orders'
import ShopOrderReport from '../shop-order-report/shop-order-report'
import ShopRequestCount from '../shop-request-count/shop-request-count'
import UserCount from '../user-count/user-count'
import HomeCharts from './components/home-charts/home-charts'
interface HomeProps {}

const marks = {
	0: 'none',
	30: '30s',
	60: '60s',
}

const Home: React.FC<HomeProps> = () => {
	const { setTitle } = useContext(LayoutContext)
	const [autoReload, setAutoReload] = useState<number>(0)
	const [reloadDeadline, setReloadDeadline] = useState<any>(null)

	const handleAutoReloadChange = (value: any) => {
		setAutoReload(value)
	}

	const handleCountdownFinish = () => {
		setReloadDeadline(autoReload ? Date.now() + autoReload * 1000 : null)
	}

	useEffect(() => {
		setReloadDeadline(autoReload ? Date.now() + autoReload * 1000 : null)
	}, [autoReload])

	useEffect(() => {
		setTitle('Home')
	}, [])

	return (
		<div>
			<HomeCharts />
			<Row gutter={16} style={{ marginBottom: '30px' }}>
				<Col md={12} lg={6} xs={24}>
					<Card
						size="small"
						className="card-h140"
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span>
									Auto Reload
									{/*  ({!autoReload ? 'none' : 'every ' + autoReload + ' seconds'}) */}
								</span>
								<div className="widget-actions">
									<div className="auto-reload-countdown">
										{autoReload ? (
											<Countdown
												value={reloadDeadline}
												format="ss"
												valueStyle={{ fontSize: '16px' }}
												onFinish={handleCountdownFinish}
											/>
										) : (
											'none'
										)}
									</div>
								</div>
							</div>
						}
					>
						<div style={{ textAlign: 'center', padding: '16px' }}>
							<Slider
								marks={marks}
								step={30}
								defaultValue={autoReload}
								max={60}
								onChange={handleAutoReloadChange}
							/>
						</div>
					</Card>
				</Col>
				<Col md={12} lg={6} xs={24}>
					<UserCount {...{ init: true, autoReload }}></UserCount>
				</Col>
				<Col md={12} lg={6} xs={24}>
					<ShopRequestCount {...{ init: true }}></ShopRequestCount>
				</Col>
				<Col md={12} lg={6} xs={24}>
					<Card
						size="small"
						className="card-h140"
						title={
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span></span>
								<div className="widget-actions">
									<Button
										icon={<ReloadOutlined />}
										type="primary"
										size="small"
										ghost
									/>
									<Button
										icon={<ArrowRightOutlined />}
										type="primary"
										size="small"
										ghost
									/>
								</div>
							</div>
						}
					>
						<div style={{ textAlign: 'center' }}>col-3</div>
					</Card>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={12} xs={24}>
					<PendingOrders {...{ init: true }}></PendingOrders>
				</Col>
				<Col md={12} xs={24}>
					<ShopOrderReport {...{ init: true }}></ShopOrderReport>
				</Col>
			</Row>
		</div>
	)
}
export default Home
