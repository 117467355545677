import { Spin } from 'antd'
import React, { useContext } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/containers/login/login'
import { AuthContext } from './components/providers/auth'
import { SocketProvider } from './components/providers/socket'
import MainLayout from './components/shared/main-layout/main-layout'
import { appRoutes } from './routes-app'
interface RoutesProps {}
const AuthRoutes: React.FC = () => {
	const { authLoading } = useContext(AuthContext)
	return (
		<Spin spinning={authLoading ? authLoading : false}>
			<Switch>
				<Route path="/" exact>
					<Login />
				</Route>
			</Switch>
		</Spin>
	)
}

const AppRoutes: React.FC = () => {
	return (
		<SocketProvider>
			<Switch>
				<MainLayout>
					{appRoutes.map((route) => {
						return (
							<Route key={route.path} path={route.path} exact={route.exact}>
								<route.component />
							</Route>
						)
					})}
				</MainLayout>
			</Switch>
		</SocketProvider>
	)
}
const Routes: React.FC<RoutesProps> = () => {
	const { user } = useContext(AuthContext)
	return <BrowserRouter>{!user ? <AuthRoutes /> : <AppRoutes />}</BrowserRouter>
}
export default Routes
