import { Button, Descriptions, Modal, Radio, Spin, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import orderService from '../../../services/order.service'
import { LayoutContext } from '../../providers/layout'
interface ReportsProps {}

interface ReportsWrapperProps {
	data: any
	onPeriodChange: (val: any) => void
	period: any
	hideShops?: boolean
}

const ReportsWrapper: React.FC<ReportsWrapperProps> = ({
	data,
	onPeriodChange,
	period,
	hideShops,
}) => {
	return (
		<div style={{ background: 'white', padding: 16 }}>
			<div
				style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
			>
				<Radio.Group
					buttonStyle="solid"
					size="small"
					value={period}
					onChange={(e) => onPeriodChange(e.target.value)}
				>
					<Radio.Button value="daily">Day</Radio.Button>
					<Radio.Button value="weekly">Week</Radio.Button>
					<Radio.Button value="monthly">Month</Radio.Button>
					<Radio.Button value="all">All</Radio.Button>
				</Radio.Group>
			</div>
			<Tabs defaultActiveKey={!hideShops ? '1' : '2'} destroyInactiveTabPane>
				{!hideShops && (
					<Tabs.TabPane tab="Shops" key="1">
						{data && (
							<GenerateDescription
								labelObject={data.totalByShops}
								valueObject={data.totalPriceByShops}
								shopIdMap={data.shopIdMap}
								showDetailButton
							/>
						)}
					</Tabs.TabPane>
				)}
				<Tabs.TabPane tab="Payment Types" key="2">
					{data && (
						<GenerateDescription
							labelObject={data.totalByPaymentTypes}
							valueObject={data.totalPriceByPaymentTypes}
						/>
					)}
				</Tabs.TabPane>
				<Tabs.TabPane tab="Customers" key="3">
					{data && (
						<GenerateDescription
							labelObject={data.totalByTopTenCustomer}
							valueObject={data.totalPriceByTopTenCustomer}
						/>
					)}
				</Tabs.TabPane>
				<Tabs.TabPane tab="Products" key="4">
					{data && (
						<GenerateDescription
							labelObject={data.totalByProducts}
							valueObject={data.totalPriceByProducts}
							shopIdMap={null}
							showDetailButton={false}
						/>
					)}
				</Tabs.TabPane>
			</Tabs>
		</div>
	)
}

const GenerateDescription: React.FC<{
	labelObject: any
	valueObject: any
	shopIdMap?: any
	showDetailButton?: boolean
}> = ({ labelObject, valueObject, shopIdMap, showDetailButton }) => {
	const { setLoading, loading } = useContext(LayoutContext)
	const [modalData, setModalData] = useState<any>(null)
	const [modalPeriod, setModalPeriod] = useState<any>('all')
	const [shopId, setShopId] = useState<any>(null)
	const [shopName, setShopName] = useState<any>(null)

	const [modalVisible, setModalVisible] = useState(false)
	useEffect(() => {
		setLoading(true)
		orderService.getFilteredOrders(modalPeriod, shopId).then((response) => {
			setModalData(response)
			setLoading(false)
		})
	}, [modalPeriod, shopId])
	return (
		<>
			<Descriptions bordered column={1}>
				{Object.keys(labelObject).map((key) => (
					<Descriptions.Item label={key} key={key}>
						<Descriptions bordered>
							<Descriptions.Item label="Qty.">
								{labelObject[key]}
							</Descriptions.Item>
							<Descriptions.Item label="Amount">
								{(+valueObject[key]).toFixed(2)} ₺
							</Descriptions.Item>
							{showDetailButton && (
								<Descriptions.Item>
									<Button
										onClick={() => {
											setShopId(shopIdMap[key])
											setShopName(key)
											setModalVisible(true)
										}}
									>
										Details
									</Button>
								</Descriptions.Item>
							)}
						</Descriptions>
					</Descriptions.Item>
				))}
			</Descriptions>
			{modalVisible && (
				<Modal
					visible
					width="80%"
					title={'Report for ' + shopName}
					onCancel={() => setModalVisible(false)}
					footer={[
						<Button key="back" onClick={() => setModalVisible(false)}>
							Close
						</Button>,
					]}
				>
					<Spin spinning={loading}>
						<ReportsWrapper
							period={modalPeriod}
							onPeriodChange={setModalPeriod}
							data={modalData}
							hideShops
						/>
					</Spin>
				</Modal>
			)}
		</>
	)
}
const Reports: React.FC<ReportsProps> = () => {
	const [data, setData] = useState<any>(null)
	const { setLoading } = useContext(LayoutContext)
	const [period, setPeriod] = useState<any>('monthly')
	useAsyncEffect(async () => {
		setLoading(true)
		const response = await orderService.getFilteredOrders(period, 'all')
		setData(response)
		setLoading(false)
	}, [period])
	return (
		<ReportsWrapper period={period} onPeriodChange={setPeriod} data={data} />
	)
}
export default Reports
