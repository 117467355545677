import { BASE_URL } from '../config';
import api from './api.service';

export default {
  getUsersByName: async (searchTerm: string) => {
    const response = await api.get(BASE_URL + '/user/getUsersByName?searchTerm=' + searchTerm);
    return response.data;
  },

  getAllUsers: async () => {
    const response = await api.get(BASE_URL + '/user/getAllUsers');
    return response.data;
  },

  getUserById: async (userId: string) => {
    const response = await api.get(BASE_URL + '/user/getUserById?user_id=' + userId);
    return response.data;
  },
  updateUser: async (user: any) => {
    const response = await api.post(BASE_URL + '/user/updateUser', { user });
    return response.data;
  },
  addNewUser: async (user: any) => {
    const response = await api.post(BASE_URL + '/user/createUser', { user })
    return response.data;
  }
};
