import {
	BarChartOutlined,
	FlagOutlined,
	MailOutlined,
	MessageOutlined,
	UploadOutlined,
	UsergroupAddOutlined,
	UserOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
interface MenuProps {}
const MainMenu: React.FC<MenuProps> = () => {
	return (
		<Menu theme="dark" mode="inline" defaultSelectedKeys={['/']}>
			<Menu.Item key="/">
				<Link to={'/'}>
					<UserOutlined />
					<span>Home</span>
				</Link>
			</Menu.Item>
			<Menu.SubMenu
				key="shopsSubMenu"
				title={
					<>
						<VideoCameraOutlined />
						<span>Shops</span>
					</>
				}
			>
				<Menu.Item key="/shops">
					<Link to={'/shops'}>
						<VideoCameraOutlined />
						<span>All Shops</span>
					</Link>
				</Menu.Item>
				<Menu.Item key="/approve-shops">
					<Link to={'/approve-shops'}>
						<VideoCameraOutlined />
						<span>Approve shops</span>
					</Link>
				</Menu.Item>
			</Menu.SubMenu>
			<Menu.Item key="/shop-requests">
				<Link to="/shop-requests">
					<VideoCameraOutlined />
					<span>Shop requests</span>
				</Link>
			</Menu.Item>
			<Menu.Item key="/orders">
				<Link to={'/orders'}>
					<MailOutlined />
					<span>Orders</span>
				</Link>
			</Menu.Item>
			<Menu.Item key="/users">
				<Link to={'/users'}>
					<UsergroupAddOutlined />
					<span>Users</span>
				</Link>
			</Menu.Item>
			<Menu.Item key="/conversations">
				<Link to={'/conversations'}>
					<MessageOutlined />
					<span>Conversations</span>
				</Link>
			</Menu.Item>
			<Menu.Item key="/config">
				<Link to={'/config'}>
					<UploadOutlined />
					<span>Config</span>
				</Link>
			</Menu.Item>
			<Menu.Item key="/bulk-notification">
				<Link to={'/bulk-notification'}>
					<MailOutlined />
					<span>Send Notification / SMS</span>
				</Link>
			</Menu.Item>
			<Menu.Item key="/reports">
				<Link to={'/reports'}>
					<BarChartOutlined />
					<span>Reports</span>
				</Link>
			</Menu.Item>
			<Menu.Item key="/support">
				<Link to={'/support'}>
					<FlagOutlined />
					<span>Support</span>
				</Link>
			</Menu.Item>
		</Menu>
	)
}
export default MainMenu
