import { CopyTwoTone, EditTwoTone, FolderViewOutlined, MailOutlined } from '@ant-design/icons';
import { Descriptions, message, Table } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Column from 'antd/lib/table/Column';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { LocalStorageUser } from '../../interfaces/user.interface';
import Timeago from '../shared/timeago/timeago';

interface UserTableProps {
  users: LocalStorageUser[];
  visibleAddressModal: (addresses: []) => void;
  visibleOrdersModal: (userId: '') => void;
}
interface ExpandedRowProps {
  name: string;
  _id: string;
  email: string;
  phone: string;
  createDate: string;
}

const ExpandedRow: React.FC<ExpandedRowProps> = ({ name, _id, email, phone, createDate }) => {
  return (
    <Descriptions layout='horizontal'>
      <Descriptions.Item label='Id'>{_id}</Descriptions.Item>
      <Descriptions.Item label='Name'>{name}</Descriptions.Item>
      <Descriptions.Item label='Email'>{email}</Descriptions.Item>
      <Descriptions.Item label='Phone'>{phone}</Descriptions.Item>
      <Descriptions.Item label='Created At'>{createDate}</Descriptions.Item>
    </Descriptions>
  );
};

const UserTable: React.FC<UserTableProps> = ({
  users,
  visibleAddressModal,
  visibleOrdersModal,
}) => {
  const { md } = useBreakpoint();
  return (
    <Table
      dataSource={users}
      rowKey='_id'
      pagination={undefined}
      expandable={
        !md
          ? {
            expandedRowRender: (record: any) => <ExpandedRow {...record} />,
          }
          : undefined
      }
    >
      {md && (
        <Column
          ellipsis
          title='Id'
          dataIndex='_id'
          key='_id'
          render={(id) => (
            <div>
              <CopyToClipboard onCopy={() => message.success('Copied', 0.4)} text={id}>
                <CopyTwoTone className='mr-8' />
              </CopyToClipboard>
              {id}
            </div>
          )}
        ></Column>
      )}
      <Column ellipsis title='Name' dataIndex='name'></Column>

      <Column ellipsis title='Email' dataIndex='email'></Column>

      <Column ellipsis title='Phone' dataIndex='phone'></Column>

      {md && (
        <Column
          ellipsis
          title='Created At'
          dataIndex='createDate'
          render={(createDate) => {
            return <Timeago date={createDate}></Timeago>;
          }}
        ></Column>
      )}
      <Column
        ellipsis
        title='Addresses'
        dataIndex='addresses'
        render={(addresses) => (
          <>
            <FolderViewOutlined
              onClick={(status) => (addresses ? visibleAddressModal(addresses) : null)}
            />
          </>
        )}
      ></Column>
      <Column
        ellipsis
        title='Orders'
        dataIndex='_id'
        render={(userId) => (
          <>
            <FolderViewOutlined
              onClick={(status) => (userId ? visibleOrdersModal(userId) : null)}
            />
          </>
        )}
      ></Column>
      <Column
        ellipsis
        title='Actions'
        key='actions'
        align='center'
        width={100}
        render={(text, record: any) => (
          <>
            <Link to={'/users/edit/' + record._id}>
              <EditTwoTone className='mr-8 fs-18' />
            </Link>
            <Link to={'/bulk-notification/' + record._id}>
              <MailOutlined className='mr-8 fs-18' />
            </Link>
          </>
        )}
      />
    </Table>
  );
};
export default UserTable;
