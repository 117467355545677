import { ArrowRightOutlined, CaretDownFilled, CaretUpFilled, ReloadOutlined } from '@ant-design/icons'
import { Badge, Button, Card, List, Radio } from 'antd'
import React, { useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import reportService from '../../../services/report.service'
import './shop-order-report.scss'

interface Props {
	init: boolean
}

const ShopOrderReport: React.FC<Props> = ({ init }) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [shops, setShops] = useState<any[]>([]);
	const [shopId, setShopId] = useState<string>('all');
	const [period, setPeriod] = useState<string>('7')
	const [sortType, setSortType] = useState<string>('asc')
	const [limit, setLimit] = useState<number>(5)

	const handlePeriodChange = (event: any) => {
		setPeriod(event.target.value)
	}

	const handleSortTypeChange = (event: any) => {
		setSortType(event.target.value)
	}

	const getShopOrderReport = async () => {
		setLoading(true)
		const response = await reportService.getShopOrderReport(shopId, period, sortType, limit);
		setShops(response.shops)
		setLoading(false)
	}

	useAsyncEffect(async () => {
		getShopOrderReport()
	}, [period, sortType])

	useAsyncEffect(async () => {
		getShopOrderReport()
	}, [])

	return (
		<Card size="small" title={
			<div style={{
				display: 'flex',
				justifyContent: 'space-between'
			}}>
				<span>Shops Order Report</span>
				<div className="widget-actions">
					<Button onClick={getShopOrderReport} icon={<ReloadOutlined />} disabled={loading} type="primary" size="small" ghost />
					<Button icon={<ArrowRightOutlined />} type="primary" size="small" ghost />
				</div>
			</div>
		}>
			<div>
				<List
					size="small"
					header={
						<div className="actions">
							<Radio.Group defaultValue={period} buttonStyle="solid" size="small" onChange={handlePeriodChange}>
								<Radio.Button value="1">Day</Radio.Button>
								<Radio.Button value="7">Week</Radio.Button>
								<Radio.Button value="30">Month</Radio.Button>
								<Radio.Button value="all">All</Radio.Button>
							</Radio.Group>
							<Radio.Group defaultValue={sortType} size="small" onChange={handleSortTypeChange}>
								<Radio.Button value="asc">
									<CaretDownFilled />
								</Radio.Button>
								<Radio.Button value="desc">
									<CaretUpFilled />
								</Radio.Button>
							</Radio.Group>
						</div>
					}
					footer={
						<div className="list-info">
							<i>Order count {period === 'all' ? 'of all time' : period === '1' ? 'in last 24 hours' : ('in the last ' + period + ' days')} </i>
						</div>
					}
					bordered
					loading={loading}
					dataSource={shops}
					renderItem={item => <List.Item className={item.status}
						actions={[
							<span style={{
								display: 'flex',
								alignItems: 'center'
							}}>
								<Badge count={item.total_amount.toFixed(2) + ' TL'} style={{
									marginRight: '10px',
									backgroundColor: 'transparent',
									color: '#52c41a',
									border: '1px solid',
									fontWeight: 'bold',
									height: '22px'
								}} />
								<b className="order-count">{item.order_count}</b>
							</span>
						]}
					>{item.o_shopName}</List.Item>}
				/>
			</div>
		</Card>
	)
}
export default ShopOrderReport
