import { Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import { Order } from '../../interfaces/order.interface'
import ProductList from '../shared/product-list/product-list'
import Timeago from '../shared/timeago/timeago'
interface OrderTableProps {
	orders: Order[]
	clientSidePagination?: boolean
}

const OrderTable: React.FC<OrderTableProps> = ({
	orders,
	clientSidePagination,
}) => {
	return (
		<Table
			dataSource={orders}
			rowKey="id"
			pagination={
				!!clientSidePagination
					? { position: 'bottomCenter' as any, defaultPageSize: 10 }
					: false
			}
			expandable={{
				expandedRowRender: (record: any) => <ProductList order={record} />,
				expandRowByClick: true,
			}}
		>
			<Column ellipsis title="Id" dataIndex="id"></Column>
			<Column
				ellipsis
				title="Created At"
				dataIndex="createdAt"
				render={(createdAt) => {
					console.log(createdAt)
					return <Timeago date={createdAt}></Timeago>
				}}
			></Column>
			<Column ellipsis title="Shop name" dataIndex="shopName"></Column>
			<Column ellipsis title="User name" dataIndex="userName"></Column>
			<Column
				ellipsis
				title="Status"
				dataIndex="status"
				render={(status) => (
					<span style={{ textTransform: 'capitalize' }}>{status}</span>
				)}
			></Column>
			<Column ellipsis title="Total Amount" dataIndex="totalAmount"></Column>
		</Table>
	)
}
export default OrderTable
