import { BASE_URL } from '../config';
import api from './api.service';
export default {
    getLastChatBetweenUsers: async (shopId: any, userId: any) => {
        const response = await api.post(BASE_URL + '/getLastChatRepositoryWithTheShopOrUser', null, {
            params: {
                userId,
                shopId
            }
        });
        return response.data;
    },
    getChatHistory: async (senderId: any, receiverId: any, lastDate: any) => {
        const response = await api.post(BASE_URL + '/getchathistory', null, {
            params: {
                senderId,
                receiverId,
                lastDate
            }
        });
        return response.data;
    },
    getActiveChats: async (senderId: any, lastDate?: any) => {
        try {
            const res = await api.post(BASE_URL + '/getchatrepositorybyshop', null, {
                params: {
                    userId: senderId,
                    lastDate
                }
            });
            return res.data.chats;
        } catch (_) {
            return [];
        }
    },
    createChatObject: (
        type: any,
        sender: any,
        user: any,
        receiver: any,
        message: any,
        oneSignalPlayerId: any,
        chatid: any,
        isSenderBot = 'false'
    ) => {
        const chat: any = {
            receiver: {
                _id: receiver._id,
                name: receiver.name,
                image: receiver.image
            },
            sender: {
                _id: sender._id,
                name: sender.name,
                image: sender.image,
                tid: sender.tid,
                isBot: isSenderBot
            },
            user: { _id: user._id, name: user.name, image: user.image },
            message: { body: message },
            type,
            chatid,
            action: null,
            date: null
        };

        return chat;
    },
    createChatSocketMessageObject: (
        id: any,
        type: any,
        sender: any,
        user: any,
        receiver: any,
        message: any,
        oneSignalPlayerId: any,
        chatid: any,
        isSenderBot = 'false'
    ) => {
        const msg: any = {
            i: id,
            t: type,
            s: {
                id: sender._id,
                uid: user._id,
                name: sender.name,
                tid: sender.tid,
                isBot: isSenderBot
            },
            r: { id: receiver._id, name: receiver.name },
            m: { b: message },
            c: chatid,
            o: oneSignalPlayerId,
            a: null,
            d: new Date().toISOString()
        };

        return msg;
    },
    saveChat: async (chat: any) => {
        const body = { chat };
        const res = await api.post(BASE_URL + '/savechat', body);
        return res.data;
    },
    saveChatRepository: async (chat: any) => {
        const body = { chat };
        const response = await api.post(BASE_URL + '/savechatrepository', body);
        return response.data;
    },

}