import { Tabs } from 'antd'
import React, { useContext, useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import configService from '../../../services/config.service'
import { LayoutContext } from '../../providers/layout'
import AppVersion from './components/app-version'
interface ConfigProps {}
const Config: React.FC<ConfigProps> = () => {
	const [appVersion, setAppVersion] = useState<any>(null)
	const { setLoading } = useContext(LayoutContext)
	useAsyncEffect(async () => {
		setLoading(true)
		const response = await configService.getAppVersion()
		setAppVersion(response)
		setLoading(false)
		console.log(response)
	}, [])
	const onVersionChange = (value: string[], platform: 'ios' | 'android') => {
		setAppVersion((prev: any) => ({
			...prev,
			[platform]: value,
		}))
	}
	const versionSave = () => {
		setLoading(true)
		configService.updateAppVersion(appVersion).then((response) => {
			console.log(response)
			setLoading(false)
		})
	}
	return (
		<Tabs defaultActiveKey="1">
			<Tabs.TabPane tab="App Version" key="1">
				<AppVersion
					appVersion={appVersion}
					onVersionChange={onVersionChange}
					save={versionSave}
				/>
			</Tabs.TabPane>
		</Tabs>
	)
}
export default Config
