import axios from 'axios';
import { QFLOW_BASE_URL } from './../config';
export default {
  getOrdersByShopId: async (shopId: string, page: number) => {
    const response = await axios.get(QFLOW_BASE_URL + `/order/shop/${shopId}/${page}`);
    return response.data;
  },

  getOrdersByUserId: async (userId: string) => {
    const response = await axios.get(QFLOW_BASE_URL + `/order/user/${userId}`);
    return response.data;
  },

  getOrdersByUserIdAndShopId: async (userId: string, shopId: string) => {
    const response = await axios.get(QFLOW_BASE_URL + '/order/user/' + userId + '/' + shopId);
    return response.data;
  },
  getOrdersFromAllShops: async (page: number) => {
    const response = await axios.get(QFLOW_BASE_URL + `/order/all/${page}`);
    return response.data;
  },
  getFilteredOrders: async (date: string, shopId: string, type?: string) => {
    const response = await axios.post(QFLOW_BASE_URL + `/order/filtered-orders`, { date, shopId, type });
    return response.data;
  }
};
