import { Button, Input } from 'antd'
import React, { useState } from 'react'
import { sendMessage } from '../../../../../helpers/send-message'
import chatService from '../../../../../services/chat.service'
import UserSelect from '../../../conversations/components/user-select'
interface NewChatProps {
	supportShop: any
	onSubmitted: () => void
}
const NewChat: React.FC<NewChatProps> = ({ supportShop, onSubmitted }) => {
	const [selectedUser, setSelectedUser] = useState<any>(null)
	const [message, setMessage] = useState('')
	const handleSend = async () => {
		const lastChat = await chatService.getLastChatBetweenUsers(
			supportShop._id,
			selectedUser._id
		)
		console.log(lastChat)
		if (lastChat && lastChat.chatid) {
			setMessage('')
			setSelectedUser(null)
			await sendMessage(
				supportShop,
				selectedUser,
				lastChat.chatid,
				message,
				null,
				'tm'
			)
			//  else {
			// 	await createNewChatRepository(
			// 		selectedUser,
			// 		supportShop,
			// 		message,
			// 		lastChat.chatid
			// 	)
			// }
			onSubmitted()
		}
	}
	return (
		<div>
			<UserSelect onChange={setSelectedUser} />
			<Input.TextArea
				rows={3}
				placeholder="Message"
				style={{ marginTop: 16 }}
				value={message}
				onChange={(e) => setMessage(e.target.value)}
			></Input.TextArea>
			<Button style={{ marginTop: 16 }} onClick={handleSend}>
				Send Message
			</Button>
		</div>
	)
}
export default NewChat
