import axios from 'axios';
import authService from './auth.service';

const api = axios.create();
api.interceptors.request.use(req => {
    req.params = req.params || {};
    req.params['token'] = authService.getToken();
    return req;
})

export default api;
