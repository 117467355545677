import { ArrowRightOutlined, ReloadOutlined } from '@ant-design/icons'
import { Badge, Button, Card, List } from 'antd'
import React, { useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import reportService from '../../../services/report.service'
import TimeAgo from '../../shared/timeago/timeago'
import './pending-orders.scss'

interface Props {
	init: boolean
}

const MAX_ORDER_LIMIT = 20;
const DEFAULT_ORDER_LIMIT = 5;
const SHOW_MORE_AMOUNT = 5;

const PendingOrders: React.FC<Props> = ({ init }) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [orders, setOrders] = useState<any[]>([]);
	const [shopId, setShopId] = useState<string>('all');
	const [limit, setLimit] = useState<number>(DEFAULT_ORDER_LIMIT)
	const [allOrdersFetched, setAllOrdersFetched] = useState<boolean>(false)

	const getPendingOrders = async (reload: boolean) => {
		setLoading(true);
		const response = await reportService.getPendingOrders(shopId, limit);
		if (
			!reload &&
			(
				(response.orders && response.orders.length === orders.length) ||
				(orders.length === 0 && response.orders && response.orders.length < DEFAULT_ORDER_LIMIT)
			)
		) {
			setAllOrdersFetched(true)
		}
		setOrders(response.orders)
		setLoading(false)
	}

	const handleShowMore = () => {
		setLimit(limit + SHOW_MORE_AMOUNT);
	}

	useAsyncEffect(async () => {
		getPendingOrders(false)
	}, [])

	useAsyncEffect(async () => {
		getPendingOrders(false)
	}, [limit])

	return (
		<Card size="small" title={
			<div style={{
				display: 'flex',
				justifyContent: 'space-between'
			}}>
				<span>Pending Orders</span>
				<div className="widget-actions">
					<Button onClick={() => getPendingOrders(true)} icon={<ReloadOutlined />} disabled={loading} type="primary" size="small" ghost />
					<Button icon={<ArrowRightOutlined />} type="primary" size="small" ghost />
				</div>
			</div>
		}>
			<div>
				<List
					size="small"
					header={
						<div className="list-info">
							<i>Showing pending orders from the last 24 hours</i>
						</div>
					}
					/* footer={
						<div className="order-count">
							<i>Order count</i>
						</div>
					} */
					bordered
					loading={loading}
					dataSource={orders}
					renderItem={item => <List.Item className={item.status}
						actions={[
							<>
								<Badge count={item.o_totalAmount.toFixed(2) + ' TL'} style={{
									marginRight: '10px',
									backgroundColor: 'transparent',
									color: '#52c41a',
									border: '1px solid',
									fontWeight: 'bold',
									height: '22px'
								}} />
								<TimeAgo date={item.o_createdAt}></TimeAgo>
							</>
						]}
					>
						{item.o_shopName}
					</List.Item>}
				/>
				{
					!loading && limit < MAX_ORDER_LIMIT && orders && orders.length && orders.length < MAX_ORDER_LIMIT && !allOrdersFetched ?
						<div className="show-more">
							<Button onClick={handleShowMore} type="primary" ghost>
								Show More
							</Button>
						</div> : null
				}
			</div>
		</Card>
	)
}
export default PendingOrders
