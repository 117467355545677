import React from 'react'
import {
	ConfiguredOption,
	Order,
	Project,
} from '../../../interfaces/order.interface'
import './product-list.scss'
interface Props {
	order: Order
}

const getPriceForProduct = (
	project: Project,
	selectedOption: ConfiguredOption
) => {
	let extraPrice = 0
	project.projectOptions.forEach((option) => {
		if (option.selectedOption.option.hasExtraPrice) {
			extraPrice += option.selectedOption.option.extraPrice
		}
	})
	const price = +(selectedOption.option.price
		? selectedOption.option.price
		: selectedOption.option.priceWithoutDiscount)

	const quantity = +selectedOption.quantity
	return (price + extraPrice) * quantity
}

const getAdditionals = (project: Project) => {
	let additionals: string[] = []
	project.projectOptions.forEach((projectOption) => {
		if (
			['evet', 'hayır', 'hamburger', 'içecek', 'menü', 'et', 'tavuk'].indexOf(
				projectOption.selectedOption.option.text.toLowerCase()
			) === -1 &&
			projectOption.selectedOption.option.saleable === false
		) {
			additionals.push(projectOption.selectedOption.option.text)
		}
	})
	return additionals.join(', ')
}

const ProductList: React.FC<Props> = ({ order }) => {
	return (
		<div className="product-list-container">
			<div className="product-list-item">
				{order.projects.map((project) =>
					project.projectOptions!.map(({ selectedOption }) => {
						if (
							selectedOption.option.saleable === true &&
							selectedOption.quantity !== 0
						) {
							return (
								<React.Fragment key={selectedOption.option.id}>
									<div className="product-count">
										{selectedOption.quantity.toString()}{' '}
									</div>
									<div className="product-name">
										{selectedOption.option.text}
									</div>
									<div className="product-price">
										{getPriceForProduct(project, selectedOption).toFixed(2)} TL
									</div>
									<div className="additional">{getAdditionals(project)}</div>
								</React.Fragment>
							)
						}
						return null
					})
				)}
			</div>
		</div>
	)
}
export default ProductList
