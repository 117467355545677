import { Button, Input, Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import orderService from '../../../services/order.service'
import userService from '../../../services/user.service'
import { LayoutContext } from '../../providers/layout'
import OrderTable from '../../tables/order-table'
import UserTable from '../../tables/user-table'
import './users.scss'

const { Search } = Input
interface UsersProps {
	history?: any
}
const Users: React.FC<UsersProps> = ({ history }) => {
	const { setTitle, setLoading } = useContext(LayoutContext)
	const [users, setUsers] = useState<any[]>([])
	const [reload, setReload] = useState(false)
	const [filteredUsers, setFilteredUsers] = useState(users)
	const [searchText, setSearchText] = useState('')
	const [addressModalVisible, setAddressModalVisible] = useState(false)
	const [ordersModalVisible, setOrdersModalVisible] = useState(false)
	const [userAdresses, setUserAdresses] = useState<any[]>([])
	const [userOrders, setUserOrders] = useState<any[]>([])
	useEffect(() => {
		setFilteredUsers(
			searchText
				? users.filter((u) =>
						u.name.toLowerCase().includes(searchText.toLowerCase())
				  )
				: users
		)
		return () => {}
	}, [searchText])

	const visibleAddressModal = async (addresses: []) => {
		setAddressModalVisible((prev) => !prev)
		setUserAdresses(addresses)
	}

	const visibleOrdersModal = async (userId: '') => {
		setLoading(true)
		const allOrders = await orderService.getOrdersByUserId(userId)
		setUserOrders(allOrders)
		setOrdersModalVisible((prev) => !prev)
		setLoading(false)
	}
	useEffect(() => {
		setTitle('Users')
	})

	useAsyncEffect(async () => {
		setLoading(true)
		const allUsers = await userService.getAllUsers()
		setUsers(allUsers)
		setFilteredUsers(allUsers)
		setLoading(false)
	}, [reload])
	return (
		<>
			<div style={{ marginBottom: 10 }}>
				<Button type="primary" onClick={() => history.push('/users/new')}>
					Add New User
				</Button>
			</div>
			<div style={{ marginBottom: 10 }}>
				<Search
					placeholder="Search user"
					onSearch={(value) => setSearchText(value)}
					enterButton
				/>
			</div>
			<UserTable
				users={filteredUsers}
				visibleAddressModal={visibleAddressModal}
				visibleOrdersModal={visibleOrdersModal}
			/>
			<Modal
				title="Address"
				visible={addressModalVisible}
				onOk={() => setAddressModalVisible(false)}
				onCancel={() => setAddressModalVisible(false)}
				width={'75%'}
			>
				{userAdresses.map((ua) =>
					ua ? (
						<div className="address-card">
							<span style={{ fontWeight: 'bold' }}>Address Title:</span>{' '}
							{ua.name}
							<br />
							<span style={{ fontWeight: 'bold' }}>Address Line 1:</span>{' '}
							{ua.address_line_1}
							<br />
							<span style={{ fontWeight: 'bold' }}>Address Line 2:</span>{' '}
							{ua.address_line_2}
							<br />
							<span style={{ fontWeight: 'bold' }}>City:</span> {ua.city}
							<br />
							<span style={{ fontWeight: 'bold' }}>State:</span> {ua.state}
							<br />
							<span style={{ fontWeight: 'bold' }}>ZipCode:</span> {ua.zipCode}
							<br />
						</div>
					) : (
						<div>Kayıtlı adres bulunamadı.</div>
					)
				)}
			</Modal>
			<Modal
				title="Orders"
				visible={ordersModalVisible}
				onOk={() => setOrdersModalVisible(false)}
				onCancel={() => setOrdersModalVisible(false)}
				width={'75%'}
			>
				<OrderTable orders={userOrders} />
			</Modal>
		</>
	)
}
export default withRouter(Users)
