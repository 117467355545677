import { BASE_URL } from '../config';
import api from './api.service';

export default {
  sendBulkNotification: async (payload: any) => {
    const response = await api.post(BASE_URL + '/notification/bulk/notification', payload);
    return response.data;
  },
  sendBulkSMS: async (phoneNumbers: string[], message: string) => {
    const payload = {
      phoneNumbers,
      message
    };
    const response = await api.post(BASE_URL + '/notification/bulk/sms', payload);
    return response.data;
  },
};
