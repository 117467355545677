import React, { useEffect, useState } from 'react'
import { LocalStorageUser } from '../../interfaces/user.interface'
import authService from '../../services/auth.service'

export const AuthContext = React.createContext<{
	user: LocalStorageUser | null
	login: (username: string, password: string) => void
	logout: () => void
	authLoading: boolean
	setAuthLoading: React.Dispatch<React.SetStateAction<boolean>> | null
}>({
	user: null,
	login: (username: string, password: string) => {},
	logout: () => {},
	authLoading: false,
	setAuthLoading: null
})
interface AuthProviderProps {}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [user, setUser] = useState<LocalStorageUser | null>(null)
	const [authLoading, setAuthLoading] = useState(false)
	useEffect(() => {
		setAuthLoading(true)
		const userInfo = authService.getUserInfo()
		const token = authService.getToken()
		console.log(userInfo, token)
		if (token && userInfo) {
			authService.checkToken(token, userInfo._id).then(response => {
				if (response.success === true) {
					setUser(userInfo)
					authService.setToken(response.token)
					setAuthLoading(false)
				}
			})
		} else {
			setAuthLoading(false)
			setUser(null)
		}
	}, [])
	return (
		<AuthContext.Provider
			value={{
				user,
				login: async (username: string, password: string) => {
					const response = await authService.adminLogin(username, password)
					if (response.success === true) {
						setUser(response.user)
						authService.setToken(response.token)
						authService.setUserInfo(response.user)
					}
				},
				logout: () => {
					setUser(null)
					localStorage.clear()
				},
				authLoading,
				setAuthLoading
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
