import React from 'react'
import Routes from '../../routes'
import { AuthProvider } from './auth'
import { LayoutProvider } from './layout'

interface GlobalProviderProps {}

export const GlobalProvider: React.FC<GlobalProviderProps> = () => {
	return (
		<LayoutProvider>
			<AuthProvider>
				<Routes />
			</AuthProvider>
		</LayoutProvider>
	)
}
