import { Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import { Link } from 'react-router-dom'
import { ShopRequest } from '../../interfaces/shop-request.interface'
interface ShopRequestTableProps {
	shopRequests: ShopRequest[]
}
const ShopRequestTable: React.FC<ShopRequestTableProps> = ({
	shopRequests,
}) => {
	return (
		<Table dataSource={shopRequests} rowKey="_id">
			<Column
				ellipsis
				title="Owner Name"
				dataIndex="ownerName"
				key="ownerName"
			/>
			<Column ellipsis title="Shop Name" dataIndex="name" key="name"></Column>
			<Column
				ellipsis
				title="Address"
				dataIndex="address"
				key="address"
			></Column>
			<Column ellipsis title="Email" dataIndex="email" key="email"></Column>
			<Column ellipsis title="Phone" dataIndex="phone" key="phone"></Column>
			<Column
				title="Description"
				dataIndex="description"
				key="description"
			></Column>
			<Column
				title="User"
				dataIndex="userId"
				key="userId"
				render={(userId) =>
					userId === 'whappo-website' ? 'From website' : userId
				}
			/>
			<Column
				title="Actions"
				key="actions"
				render={(_, record: any) => (
					<Link to={'/shops/new?requestId=' + record._id}>Add shop</Link>
				)}
			></Column>
		</Table>
	)
}
export default ShopRequestTable
