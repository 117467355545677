import { Button } from 'antd'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import { Order } from '../../../interfaces/order.interface'
import orderService from '../../../services/order.service'
import { LayoutContext } from '../../providers/layout'
import OrderTable from '../../tables/order-table'
interface ShopOrdersProps {}
const ShopOrders: React.FC<ShopOrdersProps> = () => {
	const { shopId } = useParams()
	const { setLoading, setTitle } = useContext(LayoutContext)
	const [orders, setOrders] = useState<Order[]>([])
	const [page, setPage] = useState<number>(1)
	useAsyncEffect(async () => {
		setLoading(true)
		const ordersResponse = await orderService.getOrdersByShopId(shopId!, page)
		setOrders(ordersResponse)
		setTitle('Orders')
		if (ordersResponse && ordersResponse.length !== 0) {
			setTitle(
				'Orders for ' + ordersResponse.find((x: Order) => x.shopName).shopName
			)
		}
		setLoading(false)
	}, [page])
	return (
		<>
			<OrderTable orders={orders} />
			<div>
				<div
					style={{
						width: '50%',
						textAlign: 'left',
						float: 'left',
						minHeight: 1,
						padding: '10px 0',
					}}
				>
					{page !== 1 && (
						<Button type="primary" onClick={() => setPage((prev) => prev - 1)}>
							Previous
						</Button>
					)}
				</div>
				<div
					style={{
						width: '50%',
						textAlign: 'right',
						float: 'left',
						minHeight: 1,
						padding: '10px 0',
					}}
				>
					{orders.length === 15 && (
						<Button type="primary" onClick={() => setPage((prev) => prev + 1)}>
							Next
						</Button>
					)}
				</div>
			</div>
		</>
	)
}
export default ShopOrders
