import { Card, Radio } from 'antd'
import React, { useContext, useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import reportService from '../../../../../../services/report.service'
import { LayoutContext } from '../../../../../providers/layout'
import BarChart from '../../../../../shared/bar-chart/bar-chart'
interface ShopOrdersProps {}
const ShopOrders: React.FC<ShopOrdersProps> = () => {
	const { setLoading } = useContext(LayoutContext)
	const [data, setData] = useState<any[]>([])
	const [period, setPeriod] = useState('30')
	const handlePeriodChange = (event: any) => {
		setPeriod(event.target.value)
	}
	useAsyncEffect(async () => {
		setLoading(true)
		const response = await reportService.getShopOrderReport(
			'all',
			period,
			'ASC',
			5
		)
		setData(
			response.shops.map((x: any) => ({
				name: x.o_shopName,
				shortName:
					x.o_shopName.length > 10
						? x.o_shopName.slice(0, 10) + '...'
						: x.o_shopName,
				Orders: x.order_count,
			}))
		)
		setLoading(false)
	}, [period])
	return (
		<Card size="small">
			<h1 style={{ padding: '4px 16px' }}>Orders for shop</h1>
			<div style={{ padding: '4px 16px' }} className="actions">
				<Radio.Group
					defaultValue={period}
					buttonStyle="solid"
					size="small"
					onChange={handlePeriodChange}
				>
					<Radio.Button value="1">Day</Radio.Button>
					<Radio.Button value="7">Week</Radio.Button>
					<Radio.Button value="30">Month</Radio.Button>
					<Radio.Button value="all">All</Radio.Button>
				</Radio.Group>
			</div>
			<BarChart
				data={data}
				nameKey="name"
				bars={[{ dataKey: 'Orders', fill: '#1890ff' }]}
			/>
		</Card>
	)
}
export default ShopOrders
