import axios from 'axios';
import { BASE_URL } from '../config';
import { LocalStorageUser } from './../interfaces/user.interface';

export default {
    getUserInfo: (): LocalStorageUser | null => {
        const user = localStorage.getItem('user');
        if (user) {
            return JSON.parse(user)
        }
        return null;
    },
    getToken: () => localStorage.getItem('token'),
    setToken: (token: string) => {
        localStorage.setItem('token', token)
    },
    setUserInfo: (user: LocalStorageUser) => {
        localStorage.setItem('user', JSON.stringify(user))
    },
    adminLogin: async (username: string, password: string) => {
        const body = "username=" + username + "&password=" + password + "&deviceid=";
        const response = await axios.post(BASE_URL + '/admin-login', body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response.data;
    },

    checkToken: async (token: string, userId: string) => {
        const body = 'token=' + token + '&deviceid=&userid=' + userId;
        const response = await axios.post(BASE_URL + '/checktoken', body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params: {
                allowToken: 'true'
            }
        });
        return response.data;
    }
}