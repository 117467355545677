import React from 'react'
import './message-list.scss'
interface MessageListProps {
	messages: any[]
	senderId: string
}
const MessageList: React.FC<MessageListProps> = ({ messages, senderId }) => {
	return (
		<>
			{messages.map((x) => (
				<div
					className={
						'message-container ' +
						(senderId === x.sender._id ? 'right' : 'left')
					}
					key={x._id}
				>
					<div
						className={'message '}
						dangerouslySetInnerHTML={{ __html: x.message.body }}
					/>
				</div>
			))}
		</>
	)
}
export default MessageList
