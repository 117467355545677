import { Col, Modal, Row } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { sendMessage } from '../../../helpers/send-message'
import { sortChats } from '../../../helpers/sorters'
import { SocketIOMessage } from '../../../interfaces/socket-io-message'
import authService from '../../../services/auth.service'
import chatService from '../../../services/chat.service'
import shopService from '../../../services/shop.service'
import SocketContext from '../../providers/socket'
import ConversationListWithSearch from '../../shared/conversation-list-with-search/conversation-list-with-search'
import MessageListWithInput from '../../shared/message-list-with-input/message-list-with-input'
import NewChat from './components/new-chat/new-chat'
import './support.scss'

interface SupportProps {}
const Support: React.FC<SupportProps> = () => {
	const { socket } = useContext(SocketContext)
	const [supportShop, setSupportShop] = useState<any>(null)
	const [chats, setChats] = useState<any[]>([])
	const [selectedChat, setSelectedChat] = useState<any>(null)
	const [messages, setMessages] = useState<any[] | null>(null)
	const [reloadList, setReloadList] = useState(false)
	const [newChatModalVisible, setNewChatModalVisible] = useState(false)
	const messagesRef = useRef<HTMLDivElement>(null)
	const onConversationClick = (chat: any) => {
		setSelectedChat(chat)
	}
	const getUserId = (chat: any) => {
		return supportShop._id === chat.sender._id
			? chat.receiver._id
			: chat.sender._id
	}
	const getReceiver = (chat: any) => {
		return supportShop._id === chat.sender._id ? chat.receiver : chat.sender
	}
	useAsyncEffect(async () => {
		const [shop] = await shopService.getWhappoSupportShop()
		if (shop) {
			setSupportShop(shop)
			const chatsResponse = await chatService.getActiveChats(shop._id)
			setChats(chatsResponse.sort(sortChats))
			setSelectedChat(chatsResponse[0])
		}
	}, [reloadList])
	useEffect(() => {
		if (messagesRef && messagesRef.current) {
			messagesRef.current!.scrollBy({ top: 10000, behavior: 'smooth' })
		}
	}, [messages])
	useAsyncEffect(async () => {
		if (selectedChat) {
			const lastChat = await chatService.getLastChatBetweenUsers(
				supportShop._id,
				getUserId(selectedChat)
			)
			if (lastChat && lastChat.chatid) {
				const currentUser = authService.getUserInfo()
				if (socket && currentUser) {
					socket.removeAllListeners()
					socket.emit('userid', selectedChat.chatid)
					const onlineUserInfo: any = {}
					onlineUserInfo.user_id = supportShop._id
					onlineUserInfo.one_signal_player_id = null
					onlineUserInfo.room_id = selectedChat.chatid
					socket.emit('connect_users_info', onlineUserInfo)
					const msg = {
						c: selectedChat.chatid,
						s: {
							id: supportShop._id,
							uid: currentUser._id,
						},
					}
					socket.emit('user_connected', msg)
					socket.on('tm', (msg: any) => {
						const message = new SocketIOMessage(msg)
						if (message.chatid === selectedChat.chatid) {
							console.log(message)
							setMessages((prev) => [...(prev ? prev : []), message])
						}
					})
				}
				const messagesResponse = await chatService.getChatHistory(
					supportShop._id,
					getUserId(selectedChat),
					null
				)
				setMessages(
					messagesResponse.sort(
						(a: any, b: any) =>
							new Date(a.date).getTime() - new Date(b.date).getTime()
					)
				)
			} else {
				setMessages([])
			}
		}
		return () => {
			if (socket) {
				socket.removeAllListeners()
			}
		}
	}, [selectedChat])
	return (
		supportShop &&
		selectedChat && (
			<Row>
				<Modal
					visible={newChatModalVisible}
					title="New Chat"
					footer={null}
					onCancel={() => setNewChatModalVisible(false)}
				>
					<NewChat
						supportShop={supportShop}
						onSubmitted={() => {
							setNewChatModalVisible(false)
							setReloadList((prev) => !prev)
						}}
					/>
				</Modal>
				<Col xs={24} lg={8}>
					<ConversationListWithSearch
						chats={chats}
						onConversationClick={onConversationClick}
						selectedChatId={selectedChat._id}
						supportShopId={supportShop._id}
						onNewChatClick={() => setNewChatModalVisible(true)}
					/>
				</Col>
				{messages && (
					<MessageListWithInput
						messagesRef={messagesRef}
						messages={messages}
						supportShop={supportShop}
						onSend={(value) =>
							sendMessage(
								supportShop,
								getReceiver(selectedChat),
								selectedChat.chatid,
								value,
								socket!,
								'tm'
							)
						}
						height="650px"
					/>
				)}
			</Row>
		)
	)
}
export default Support
