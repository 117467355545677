import authService from '../services/auth.service';
import chatService from '../services/chat.service';

export const sendMessage = async (
    selectedShop: any,
    receiver: any,
    chatid: string,
    message: string,
    socket: SocketIOClient.Socket | null,
    type: string
) => {
    const currentUser = authService.getUserInfo();
    const chat = chatService.createChatObject(
        type,
        selectedShop,
        currentUser,
        receiver,
        message,
        null,
        chatid,
        'false'
    );
    const result = await chatService.saveChat(chat);
    const msg = chatService.createChatSocketMessageObject(
        result._id,
        type,
        selectedShop,
        currentUser,
        receiver,
        message,
        '',
        chatid,
        'false'
    );
    msg.d = msg.date = new Date().toISOString();
    await chatService.saveChatRepository(chat);
    if (socket) {
        socket.emit('tm', msg);
    }
}

// export const createNewChatRepository = async (
//     selectedUser: any,
//     selectedShop: any,
//     message: string,
//     chatid: string,

// ) => {
//     const currentUser = authService.getUserInfo();
//     const chat = chatService.createChatObject(
//         'tm',
//         selectedShop,
//         currentUser,
//         selectedUser,
//         message,
//         null,
//         chatid,
//         'false'
//     );
//     const result = await chatService.saveChat(chat);
//     const msg = chatService.createChatSocketMessageObject(
//         result._id,
//         'tm',
//         selectedShop,
//         currentUser,
//         selectedUser,
//         message,
//         '',
//         chatid,
//         'false'
//     );
//     msg.d = msg.date = new Date().toISOString();
// }