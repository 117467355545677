import React, { useContext, useState } from 'react'
import { useLocation, useParams, withRouter } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import shopService from '../../../services/shop.service'
import ShopForm from '../../forms/shop-form/shop-form'
import { LayoutContext } from '../../providers/layout'
interface UpsertShopProps {
	history?: any
}
const UpsertShop: React.FC<UpsertShopProps> = ({ history }) => {
	const { setLoading, loading, setTitle } = useContext(LayoutContext)
	const { shopId } = useParams()
	const [shop, setShop] = useState<any>(null)
	const [shopRequestId, setShopRequestId] = useState('')
	const { search } = useLocation()
	useAsyncEffect(async () => {
		if (!!search) {
			setLoading(true)
			const requestId = search.split('/')[0].split('requestId=')[1]
			if (requestId) {
				setShopRequestId(requestId)
				const response = await shopService.getShopRequest(requestId)
				const newShop: any = {}
				if (response.address) {
					newShop.location = {
						address: response.address,
						type: 'Point',
					}
				}
				const keys = ['name', 'phone']
				keys.forEach((key) => {
					newShop[key] = (response as any)[key]
				})
				if (response.userId && response.userId !== 'whappo-website') {
					newShop.creatorByUserId = response.userId
				}
				setShop(newShop)
				setLoading(false)
			}
		}
	}, [search])
	useAsyncEffect(async () => {
		if (shopId) {
			setLoading(true)
			const response = await shopService.getShopById(shopId!)
			setTitle('Edit shop ' + response[0].name)
			setShop(response[0])
			setLoading(false)
		} else {
			setTitle('Add new shop')
		}
	}, [])
	return !loading ? (
		<ShopForm
			onSubmit={async (values) => {
				setLoading(true)
				await shopService.upsertShop(values)
				if (shopRequestId) {
					await shopService.completeShopRequest(shopRequestId)
				}
				setLoading(false)
				history.push('/shops')
			}}
			shop={shop}
		/>
	) : null
}
export default withRouter(UpsertShop)
