import {
	ExclamationCircleOutlined,
	HomeOutlined,
	MailOutlined,
	PhoneOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Modal, notification, Radio, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useContext, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import notificationService from '../../../services/notification.service'
import userService from '../../../services/user.service'
import { LayoutContext } from '../../providers/layout'
import './bulk-notification.scss'
const { confirm } = Modal

interface BulkNotificationProps {
	history?: any
}

const BulkNotification: React.FC<BulkNotificationProps> = ({ history }) => {
	const { setLoading, loading, setTitle } = useContext(LayoutContext)
	const { userId } = useParams()
	const [form] = Form.useForm()
	const [users, setUsers] = useState<any[]>([])
	const [filteredUsers, setFilteredUsers] = useState<any[]>([])
	const [mode, setMode] = useState<string>('notification')
	const [sendAll, setSendAll] = useState<boolean>(false)
	const [initValues] = useState<any>({
		userIds: [],
		title: '',
		message: '',
	})
	const [selectedUsers, setSelectedUsers] = useState<any[]>([])

	const handleModeChange = (event: any) => {
		setLoading(true)
		const tempMode = event.target.value
		setMode(tempMode)
		const tempUsers =
			tempMode === 'notification' ? users : users.filter((user) => user.phone)
		setFilteredUsers(tempUsers)
		const tempSelectedUsers = tempUsers.filter(
			(tempUser) =>
				selectedUsers.indexOf(
					tempMode === 'notification' ? tempUser.phone : tempUser._id
				) > -1
		)
		setSelectedUsers(
			tempSelectedUsers.map((tempUser) =>
				tempMode === 'notification' ? tempUser._id : tempUser.phone
			)
		)
		form.setFieldsValue({
			userIds: tempSelectedUsers.map((tempUser) => tempUser.name),
		})
		setLoading(false)
	}

	const handleSendAllChange = (event: any) => {
		setSendAll(event.target.checked)
	}

	const handleSelect = (value: any, option: any) => {
		setSelectedUsers([
			...selectedUsers,
			mode === 'notification' ? option.key : option.phone,
		])
	}

	const handleDeselect = (value: any, option: any) => {
		setSelectedUsers([
			...selectedUsers.filter(
				(userId) =>
					userId !== (mode === 'notification' ? option.key : option.phone)
			),
		])
	}

	const showConfirm = (values: any) => {
		confirm({
			title: 'Are you sure?',
			icon: <ExclamationCircleOutlined />,
			content:
				(!sendAll ? selectedUsers.length : 'All') +
				'  users will get ' +
				(mode === 'notification' ? 'a notification' : 'an SMS') +
				'.',
			async onOk() {
				setLoading(true)
				let response
				if (sendAll) {
					values.userIds = ['*']
				}
				if (mode === 'notification') {
					response = await notificationService.sendBulkNotification(values)
				} else {
					response = await notificationService.sendBulkSMS(
						values.userIds,
						values.message
					)
				}
				notification[response.success ? 'success' : 'error']({
					message: 'Notification Info',
					description: response.message,
					duration: 5000,
				})
				/* form.setFieldsValue({
					userIds: [],
					title: '',
					message: ''
				});
				setSelectedUsers([]) */
				setLoading(false)
			},
			onCancel() {},
		})
	}

	const handleSubmit = (values: any) => {
		values.userIds = selectedUsers
		showConfirm(values)
	}

	useAsyncEffect(async () => {
		if (!!userId && users && users.length) {
			const related = users.find((user) => user._id === userId)
			if (!!related) {
				form.setFieldsValue({
					userIds: [related.name],
					title: '',
					message: '',
				})
				setSelectedUsers([userId])
			}
		}
	}, [userId, users])

	useAsyncEffect(async () => {}, [selectedUsers])

	useAsyncEffect(async () => {
		setTitle('Bulk Notifications')
		setLoading(true)
		const allUsers = await userService.getAllUsers()
		setUsers(allUsers)
		setFilteredUsers(allUsers)
		setLoading(false)
	}, [])

	return !loading ? (
		<div>
			<Form
				onFinish={(values) => handleSubmit(values as any)}
				wrapperCol={{ span: 18 }}
				labelCol={{ span: 4 }}
				style={{ fontWeight: 'bold' }}
				initialValues={initValues}
				form={form}
			>
				<Form.Item label="Notification Type">
					<Radio.Group
						defaultValue={mode}
						buttonStyle="solid"
						onChange={handleModeChange}
					>
						<Radio.Button value="notification">Notification</Radio.Button>
						<Radio.Button value="sms">SMS</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label="Users"
					name="userIds"
					shouldUpdate
					rules={[{ required: !sendAll, message: 'Select at least one user' }]}
				>
					<Select
						allowClear={true}
						mode="multiple"
						style={{ width: '100%' }}
						showSearch={true}
						placeholder="Search users..."
						defaultValue={initValues.userIds}
						onSelect={handleSelect}
						onDeselect={handleDeselect}
						disabled={sendAll}
					>
						{filteredUsers.map((user) => (
							<Select.Option
								key={user._id}
								value={user.name}
								phone={user.phone}
							>
								{user.name}
								<br />
								<span>
									<span className="user-dropdown-info">
										{user.phone ? (
											<span>
												<PhoneOutlined /> {user.phone}
											</span>
										) : (
											<span>
												<MailOutlined /> {user.email}
											</span>
										)}
									</span>
									{user.addresses && user.addresses.length ? (
										<span className="user-dropdown-info">
											<HomeOutlined />{' '}
											{user.addresses[0].city + '/' + user.addresses[0].state}
										</span>
									) : (
										''
									)}
								</span>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				{/* <Form.Item wrapperCol={{ offset: 4, span: 18 }}>
					<Checkbox
						checked={sendAll}
						onChange={handleSendAllChange}
						className="send-all"
					>
						Send to all
					</Checkbox>
				</Form.Item> */}
				{mode === 'notification' ? (
					<Form.Item
						label="Title"
						name="title"
						rules={[
							{
								required: mode === 'notification',
								message: 'Title is required',
							},
						]}
					>
						<Input />
					</Form.Item>
				) : (
					''
				)}
				<Form.Item
					label="Content"
					name="message"
					rules={[{ required: true, message: 'Content is required' }]}
				>
					<TextArea rows={4} />
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 4, span: 18 }}>
					<Button
						type="primary"
						size="large"
						className="send-button"
						htmlType="submit"
						disabled={!sendAll && !selectedUsers.length}
					>
						Send
					</Button>
				</Form.Item>
			</Form>
		</div>
	) : null
}
export default withRouter(BulkNotification)
